import React, {useState, useEffect, useRef}from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import '../assetsTakeOff/projectFiles.css';
import project from '../assetsTakeOff/CanvasTools/project.png';
import maximizeWindow from '../assetsTakeOff/CanvasTools/maximize-window.png';
import minimizeWindow from '../assetsTakeOff/CanvasTools/minimize-window.png';
import pdfFile from '../assetsTakeOff/CanvasTools/pdfFile.png';
import newFolder from '../assetsTakeOff/CanvasTools/new-folder.png';
import rename from '../assetsTakeOff/CanvasTools/rename.png';
import deleteIcon from '../assetsTakeOff/CanvasTools/deleteFile.png';
import moveToFolder from '../assetsTakeOff/CanvasTools/move-to-folder.png';
import slideDownArrow from '../assetsTakeOff/CanvasTools/slideDownArrow.png';
import slideUpArrow from '../assetsTakeOff/CanvasTools/slideUpArrow.png';
import duplicate from '../assetsTakeOff/CanvasTools/duplicate.png';
import showAsList from '../assetsTakeOff/CanvasTools/showAsList.png';
import showAsImg from '../assetsTakeOff/CanvasTools/showAsImg.png';
import { ThreeDot } from 'react-loading-indicators';






const ProjectFiles = ({
  plans,
  setPlans,
  folders,
  setFolders,
  PlanPages,
  handelScrollContent
}) => {
  const[images, setImages]= useState([]);
  const[renameMode, setRenameMode]= useState(false);
  const[moveMode, setMoveMode]= useState(false);
  const[deleteMode, setDeleteMode]= useState(false);
  const [selectedFolder, setSelectedFolder] = useState('');
  const[renameValue, setRenameValue]= useState('');
  const [minimize, setMinimize] = useState(() => {
    const savedMinimize = Cookies.get('minimize');
    return savedMinimize ? JSON.parse(savedMinimize) : [];
  });
  const [selectImgFolder, setSelectImgFolder] = useState({id: '', type: ''});
  const [closeProjectFiles, setCloseProjectFiles] = useState(() => {
    const closeProjectFiles = Cookies.get('closeProjectFiles');
    return closeProjectFiles === 'true'; // Ensure this returns a boolean
  });
  const[showModeImg, setShowModeImg]= useState(() => {
    const showModeImg = Cookies.get('showModeImg');
    return showModeImg === 'true'; // Ensure this returns a boolean
  });
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { projectId, pageName } = useParams();


  useEffect(() =>{
    const sortedImgs = PlanPages.sort((a, b) => (a.ImgOrder < b.ImgOrder ? -1 : a.ImgOrder > b.ImgOrder ? 1 : 0));
    setImages(sortedImgs)

  },[PlanPages])

  useEffect(() => {
    if (renameMode && selectImgFolder.id && inputRef.current) {
      inputRef.current.focus();
    }
  }, [renameMode, selectImgFolder]);

  useEffect(() => {

    const handleClickOutside = (e) => {

      const where = e.target.getAttribute('id');
      if(where !=='name' && 
        where !=='img' &&
        where !=='deleteFile' && 
        where !=='moveToFolder' &&
        where !=='moveToSelect' && 
        where !=='buttonMove' && 
        where !=='moveToOption' && 
        where !=='rename' && 
        where !=='newFolder' && 
        where !=='tool' && 
        where !=='ToolsIcon' && 
        where !=='renameInput' &&
        where !=='duplicate' &&
        where !=='imgIDI'){
        setSelectImgFolder({id: '', type: ''});
        handelRenameMode()
        }
        if(where !=='name' && 
        where !=='img' &&
        where !=='moveToFolder' && 
        where !=='moveToSelect' && 
        where !=='buttonMove' && 
        where !=='moveToOption' && 
        where !=='plans' && 
        where !=='imgIDI'){
          setMoveMode(false)
        }
        if(where !=='name' && 
        where !=='img' &&
        where !=='deleteButton' && 
        where !=='delete' && 
        where !=='cancel' &&
        where !=='ToolsIcon' && 
        where !=='deleteFile' && 
        where !=='plans' && 
        where !=='imgIDI'){
          setDeleteMode(false)
        }
    };
    const handleKeyDown = (e) => {
    
      if (e.key === 'Enter') {
        setMoveMode(false)
        handelRenameMode()
      }

    };

    window.addEventListener('click', handleClickOutside);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleKeyDown);
    };
  // eslint-disable-next-line no-use-before-define
  }, [selectImgFolder, renameValue]);


  const handelCloseProjectFiles = () =>{
    setCloseProjectFiles((prevCloseProjectFiles) => {
      const newCloseProjectFiles = !prevCloseProjectFiles;
      Cookies.set('closeProjectFiles', newCloseProjectFiles);
      return newCloseProjectFiles;
    });
  }


  const handelSelectedImgFolder = (id,type) =>{
    if(id !== selectImgFolder.id){
      setSelectImgFolder({id: id, type: type})
      setRenameMode(false)
      setDeleteMode(false)
      setRenameValue('')
    }
  }

  const openNewImg = (imgName, id, type) =>{
    setSelectImgFolder({id: id, type: type})
    handelScrollContent()
    navigate(`/manage/project/${projectId}/takeoff/canvas/page/${imgName}`); 
  }
  const handleMinimize = (id) => {
    setMinimize((prevMinimize) => {
      let newMinimize;
      if (prevMinimize.includes(id)) {
        newMinimize = prevMinimize.filter((minId) => minId !== id);
      } else {
        newMinimize = [...prevMinimize, id];
      }
      Cookies.set('minimize', JSON.stringify(newMinimize));
      return newMinimize;
    });
  };


  // Tools
  const handelShowModeImg = () =>{
    setShowModeImg((prevShowModeImg) => {
      const showModeImg = !prevShowModeImg;
      Cookies.set('showModeImg', showModeImg);
      return showModeImg;
    });
  }

  const handelDuplicate = async () =>{
    if(selectImgFolder.type === 'img'){
      const img = images.find((img)=> img.ID === selectImgFolder.id)
      if(img){
        const duplicateImg = {
          ID: Math.floor(Math.random() * 0xffffffffffffffff).toString(16).replace(/0+$/, '') || '0', // Function to generate a new unique ID
          Plan_Id: img.Plan_Id,
          Projects_ID: img.Projects_ID,
          Name: img.Name + ' (copy)', // Optional: modify the name to indicate it's a copy
          ImgOrder: img.ImgOrder,
          Data: img.Data, // Already in base64 format
          InFolder: img.InFolder
        }
        // Convert base64 image data to a Blob
        const byteCharacters = atob(img.Data); // Decode base64
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' }); // Adjust type as needed
        const file = new File([blob], 'image.png'); // You can name the file as you wish
        // Create FormData and append the file
        const formData = new FormData();
        formData.append('ID', duplicateImg.ID)
        formData.append('Plan_Id', duplicateImg.Plan_Id)
        formData.append('Projects_ID', projectId)
        formData.append('Name', duplicateImg.Name)
        formData.append('ImgOrder', duplicateImg.ImgOrder)
        formData.append('InFolder', duplicateImg.InFolder)
        formData.append('file', file);




        try{
          const response = await axios.post('https://app.api.scae.co/api/projectFiles/createDuplicate', formData);
          if (response.status === 200) {
            setImages((prevImages) => {
              const updatedImages = [...prevImages, duplicateImg];
              // Sort the images
              return updatedImages.sort((a, b) => a.ImgOrder - b.ImgOrder);
            });
          }
        }catch (error) {
          console.error("Error creating duplicate:", error);
        }
      }
    }
  }

  // delete
  const handelDeleteMode = () =>{
    setDeleteMode((prevDeleteMode) => !prevDeleteMode)
  }

  const deleteFile = async (button) =>{
    const id = selectImgFolder.id
    const type = selectImgFolder.type

    if(button !== 'cancel'){
      if(type === 'plan'){
        const planID = id
        await deletePlan(planID)
        setPlans((prevPlans) => prevPlans.filter((plan) => plan.ID !== planID));
        axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${planID}/${planID}/deletePlan`)
      }
      else if(type === 'folder'){
        if(button === 'wFiles'){
          const parentID = id
          deleteFolderAndImg(parentID)

        }
        if(button === 'oFolder'){
          const folder = folders.filter((folder) => folder.ID === id)
          const folderID = folder[0].ID
          const parentFolderID = folder[0].InFolder
          const img = images.filter((img) => img.InFolder === folderID)
          const childFolder = folders.filter((folder) => folder.InFolder === folderID)


          if(img.length > 0){
            setImages((prevImages) =>
              prevImages.map((image) =>
                image.InFolder === folderID ? { ...image, InFolder: parentFolderID } : image
              )
            );
            img.forEach((img) =>(
              axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${img.ID}/${parentFolderID}/updateImg`)
            ))
          }
          if(childFolder.length > 0){
            setFolders((prevFolders) =>
              prevFolders.map((folder) =>
                folder.InFolder === folderID ? { ...folder, InFolder: parentFolderID } : folder
              )
            );
            childFolder.forEach((folder) =>(
              axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${folder.ID}/${parentFolderID}/updateFolder`)
            ))
          }
          setFolders((prevFolders) => prevFolders.filter((folder) => folder.ID !== folderID));
          axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${folderID}/${parentFolderID}/deleteFolder`)
        }
      }
      else if(type === 'img'){
        axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${id}/${id}/deleteImg`);
        setImages((prevImages) => prevImages.filter((img) => img.ID !== id));
      }

    }else{
      setDeleteMode(false)
      setSelectImgFolder('')
    }


  } 

  const deletePlan = async (parentID) => {
    try {
      const childImg = images.filter((img) => img.InFolder === parentID);
      const childFolder = folders.filter((folder) => folder.InFolder === parentID);
  
      // Delete all child images
      for (const img of childImg) {
        await axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${img.ID}/${img.ID}/deleteImg`);
      }
  
      // Recursively delete all child folders and their contents
      for (const folder of childFolder) {
        await deletePlan(folder.ID); // Recursive call to delete child folders and images
      }
  
      // After deleting all child contents, delete the folder itself
      await axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${parentID}/${parentID}/deleteFolder`);
      setFolders((prevFolders) => prevFolders.filter((folder) => folder.ID !== parentID));
  
    } catch (error) {
      console.error("Error during deletion process:", error);
    }
  };

  const deleteFolderAndImg = async (parentID) => {
    try {
      const childImg = images.filter((img) => img.InFolder === parentID);
      const childFolder = folders.filter((folder) => folder.InFolder === parentID);
  
      // Delete all child images
      for (const img of childImg) {
        await axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${img.ID}/${img.ID}/deleteImg`);
      }
  
      // Recursively delete all child folders and their contents
      for (const folder of childFolder) {
        await deleteFolderAndImg(folder.ID); // Recursive call to delete child folders and images
      }
  
      // After deleting all child contents, delete the folder itself
      await axios.post(`https://app.api.scae.co/api/projectFiles/handelDelete/${parentID}/${parentID}/deleteFolder`);
      setFolders((prevFolders) => prevFolders.filter((folder) => folder.ID !== parentID));
  
    } catch (error) {
      console.error("Error during deletion process:", error);
    }
  };


  // ReName

  const handelRenameValueChange = (e) =>{
    setRenameValue(e.target.value)
  }
  const handelRenameMode = async () =>{
    if(renameMode === false){

      setRenameMode((prevRenameMode) => !prevRenameMode);
      const id = selectImgFolder.id
      const type = selectImgFolder.type
      if(type ==='plan'){
        const plan = plans.filter((plan) => plan.ID === id)
        setRenameValue(plan[0].Name)
      }
  
      else if(type === 'folder'){
        const folder = folders.filter((folder) => folder.ID === id)
        setRenameValue(folder[0].Name)
      }
      else if(type === 'img'){
        const img = images.filter((img) => img.ID === id)
        setRenameValue(img[0].Name)
  
      }
    }
    else{

      if(renameValue.length > 0){
        const id = selectImgFolder.id
        const type = selectImgFolder.type
        if(type === 'plan'){
          setPlans((prevPlans) =>
            prevPlans.map((plan) =>
              plan.ID === id ? { ...plan, Name: renameValue } : plan
            )
          );
          axios.post(`https://app.api.scae.co/api/projectFiles/updateName/${id}/${renameValue}/${type}`);
        }
        else if(type === 'folder'){
          setFolders((prevFolders) =>
            prevFolders.map((folder) =>
              folder.ID === id ? { ...folder, Name: renameValue } : folder
            )
          );
          axios.post(`https://app.api.scae.co/api/projectFiles/updateName/${id}/${renameValue}/${type}`);
        }
        else if(type === 'img'){
          setImages((prevImages) =>
            prevImages.map((image) =>
              image.ID === id ? { ...image, Name: renameValue } : image
            )
          );
          const result = await axios.post(`https://app.api.scae.co/api/projectFiles/updateName/${id}/${renameValue}/${type}`);
  
          if(result.status === 200){
            navigate(`/manage/project/${projectId}/takeoff/canvas/page/${renameValue}`); 
          }
        }
  
  
        setRenameValue('')
        setRenameMode(false)
      }else{
        setRenameValue('')
        setRenameMode(false)
      }
    }


  }

  // moveTo

  const handelMoveMode = () =>{
    setMoveMode((prevMoveMode) => !prevMoveMode)
  }
  const moveTo = async () =>{
    if(selectedFolder.length > 0){
      const id = selectImgFolder.id
      const type = selectImgFolder.type

      if(type === 'folder'){
        setFolders((prevFolders) =>
          prevFolders.map((folder) =>
            folder.ID === id ? { ...folder, InFolder: selectedFolder } : folder
          )
        );
        axios.post(`https://app.api.scae.co/api/projectFiles/updateInfolder/${id}/${selectedFolder}/${type}`);
      }
      else if(type === 'img'){
        setImages((prevImages) =>
          prevImages.map((image) =>
            image.ID === id ? { ...image, InFolder: selectedFolder } : image
          )
        );
        axios.post(`https://app.api.scae.co/api/projectFiles/updateInfolder/${id}/${selectedFolder}/${type}`);
      }


      setSelectedFolder('')
    }
  }

  const handleSelectChange = (event) => {
    setSelectedFolder(event.target.value);
  };


  // newFolder 

  const CreateNewFolder = () =>{
    if(selectImgFolder.id.length > 0){
      const id = selectImgFolder.id
      const type = selectImgFolder.type
      if(type === 'folder' || type ==='plan'){
        const FolderID = id
        const newID = Math.floor(Math.random() * 0xffffffffffffffff).toString(16).replace(/0+$/, '') || '0';
        const name = 'New Folder'
        const newFolder =
        {ID: newID,
        Name: name, 
        InFolder: FolderID}
        setFolders([...folders, newFolder]);

        axios.post(`https://app.api.scae.co/api/projectFiles/newFolder/${newID}/${projectId}/${name}/${FolderID}`);
      }
      else if(type === 'img'){
        const img = images.filter((img) => img.ID === id)
        const InFolderID = img[0].InFolder;
        const newID = Math.floor(Math.random() * 0xffffffffffffffff).toString(16).replace(/0+$/, '') || '0';
        const name = 'New Folder'
        const newFolder =
          {ID: newID,
          Name: name, 
          InFolder: InFolderID}
        setFolders([...folders, newFolder]);

        axios.post(`https://app.api.scae.co/api/projectFiles/newFolder/${newID}/${projectId}/${name}/${InFolderID}`);
      }


      setSelectedFolder('')
    }
    else{
      const newID = Math.floor(Math.random() * 0xffffffffffffffff).toString(16).replace(/0+$/, '') || '0';
      const name = 'New Folder'
      const newFolder =
      {ID: newID,
        Name: name, 
        InFolder: projectId}
      setFolders([...folders, newFolder]);
      axios.post(`https://app.api.scae.co/api/projectFiles/newFolder/${newID}/${projectId}/${name}/${projectId}`);
    }
  }


  
  const insertImgs = (id , index) =>{
    const img = images.filter((image) => image.InFolder === id);
    if(img.length > 0 && !showModeImg){
      return(
        <>
          <div id='imgs-content' style={{marginLeft: (index * 10)+'px'}}>
            {index !== 0 && (<div id="hLine"><div></div></div>)}

            <div id="imgs">
              {img.map((img,i) => (
              <div id="img" key={i}>
                <div id='line'><div></div></div>
                <div id="folderIcon"><img src={pdfFile} alt="pdfFile" /></div>
                <div id='name'
                  style={{
                    backgroundColor: selectImgFolder.id === img.ID ? '#f4c24f' : pageName === img.Name ? '#fbe1a4' : '',
                    width: '180px',
                    border: pageName === img.Name ? '1px solid black' : ''
                  }}
                  onDoubleClick={() => openNewImg(img.Name , img.ID, 'img')}
                  onClick={() => handelSelectedImgFolder(img.ID, 'img')}>
                  {renameMode && selectImgFolder.id === img.ID ? 
                  (<input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />) : 
                  (img.Name)}
                  
                </div>
              </div>
            ))}
            </div>
          </div>
        </>
      )
    }

    if(img.length > 0 && showModeImg){
      return(
        <>
          <div id='imgs-content' style={{marginLeft: (index * 10)+'px'}}>
            {index !== 0 && (<div id="hLine" style={{paddingBottom: showModeImg ? '44%' : '13px' }} ><div></div></div>)}

            <div id="imgs">
              {img.map((img,i) => (
              <div id="imgInDisplayImgMood" key={i}>
                <div id='line' ><div></div></div>
                <div id='imgInDisplayImgMoodDiv' 
                  style={{backgroundColor: selectImgFolder.id === img.ID ? '#f4c24f' : pageName === img.Name ? '#fbe1a4' : '',
                  border: pageName === img.Name ? '1px solid black' : ''
                  }}
                  onDoubleClick={() => openNewImg(img.Name , img.ID, 'img')}
                  onClick={() => handelSelectedImgFolder(img.ID, 'img')}>
                  <img id='imgIDI' src={`data:image/png;base64,${img.Data}`} alt='plans'/>
                  <div id='name'>
                  {renameMode && selectImgFolder.id === img.ID ? 
                  (<input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />) : 
                  (img.Name)}
                  </div>
                </div>
                
              </div>
            ))}
            </div>
          </div>
        </>
      )
    }

  }
  const insertFolder = (id , index) =>{
    const filteredFolder = folders.filter((folders) => folders.InFolder === id)
    if(filteredFolder.length > 0 && !showModeImg){
      return (
        filteredFolder.map((folder,i)=>(
         <>
         {minimize.includes(folder.ID) ? (
          <div id="folder" key={i} style={{marginLeft: (index * 10)+'px'}}>
            <div id="folderContentName">
              <div id="minimize" onClick={() => handleMinimize(folder.ID)}>
                <img src={maximizeWindow} alt="maximizeWindow" /></div>
              <div id='line'><div></div></div>
              <div id="folderIcon"><img src={project} alt="project" /></div>
              <div id='name'
                style={{backgroundColor: selectImgFolder.id === folder.ID ? '#f4c24f' : ''}}
                onClick={() => handelSelectedImgFolder(folder.ID, 'folder')}>
                  {renameMode && selectImgFolder.id === folder.ID ? 
                  (<input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />) : 
                  (folder.Name)}
                </div>
            </div>
          </div>

         ):(
          <div id="folder" key={i} style={{marginLeft: (index * 10)+'px'}}>
            <div id="folderContentName">
              <div id="minimize" onClick={() => handleMinimize(folder.ID)}>
                <img src={minimizeWindow} alt="minimizeWindow" /></div>
              <div id='line'><div></div></div>
              <div id="folderIcon"><img src={project} alt="project" /></div>
              <div id='name' 
                style={{backgroundColor: selectImgFolder.id === folder.ID ? '#f4c24f' : ''}}
                onClick={() => handelSelectedImgFolder(folder.ID, 'folder')}>
                  {renameMode && selectImgFolder.id === folder.ID ? 
                  (<input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />) : 
                  (folder.Name)}
                </div>
            </div>
            <div id="folderContent">
            {insertImgs(folder.ID, ((index+2) /2))}
            {insertFolder(folder.ID, ((index+2)/2))}
            </div>
          </div>
         )}

        </>
        ))
      )
      
    }
    if(filteredFolder.length > 0 && showModeImg){
      return (
        filteredFolder.map((folder,i)=>(
         <>
         {minimize.includes(folder.ID) ? (
          <div id="folder" key={i} style={{marginLeft: (index * 10)+'px'}}>
            <div id="folderContentName">
              <div id="minimize" onClick={() => handleMinimize(folder.ID)}>
                <img src={maximizeWindow} alt="maximizeWindow" /></div>
              <div id='line'><div></div></div>
              <div id="folderIcon"><img src={project} alt="project" /></div>
              <div id='name'
                style={{backgroundColor: selectImgFolder.id === folder.ID ? '#f4c24f' : ''}}
                onClick={() => handelSelectedImgFolder(folder.ID, 'folder')}>
                  {renameMode && selectImgFolder.id === folder.ID ? 
                  (<input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />) : 
                  (folder.Name)}
                </div>
            </div>
          </div>

         ):(
          <div id="folder" key={i} style={{marginLeft: (index * 10)+'px'}}>
            <div id="folderContentName">
              <div id="minimize" onClick={() => handleMinimize(folder.ID)}>
                <img src={minimizeWindow} alt="minimizeWindow" /></div>
              <div id='line'><div></div></div>
              <div id="folderIcon"><img src={project} alt="project" /></div>
              <div id='name' 
                style={{backgroundColor: selectImgFolder.id === folder.ID ? '#f4c24f' : ''}}
                onClick={() => handelSelectedImgFolder(folder.ID, 'folder')}>
                  {renameMode && selectImgFolder.id === folder.ID ? 
                  (<input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />) : 
                  (folder.Name)}
                </div>
            </div>
            <div id="folderContent">
            {insertImgs(folder.ID, ((index+2) /2))}
            {insertFolder(folder.ID, ((index+2)/2))}
            </div>
          </div>
         )}

        </>
        ))
      )
      
    }

  }
  const renderNewFolder = (id , index) =>{
    const NewFolder = folders.filter((folders) => folders.InFolder === projectId)
    if(NewFolder.length > 0){
      return (
        NewFolder.map((folder,i)=>(
         <>
         {minimize.includes(folder.ID) ? (
          <div id="folder" key={i} style={{marginLeft: (index * 10)+'px'}}>
            <div id="folderContentName">
              <div id="minimize" onClick={() => handleMinimize(folder.ID)}>
                <img src={maximizeWindow} alt="maximizeWindow" /></div>
              <div id='line'><div></div></div>
              <div id="folderIcon"><img src={project} alt="project" /></div>
              <div id='name'
                style={{backgroundColor: selectImgFolder.id === folder.ID ? '#f4c24f' : ''}}
                onClick={() => handelSelectedImgFolder(folder.ID, 'folder')}>{folder.Name}</div>
            </div>
          </div>

         ):(
          <div id="folder" key={i} style={{marginLeft: (index * 10)+'px'}}>
            <div id="folderContentName">
              <div id="minimize" onClick={() => handleMinimize(folder.ID)}>
                <img src={minimizeWindow} alt="minimizeWindow" /></div>
              <div id='line'><div></div></div>
              <div id="folderIcon"><img src={project} alt="project" /></div>
              <div id='name' 
                style={{backgroundColor: selectImgFolder.id === folder.ID ? '#f4c24f' : ''}}
                onClick={() => handelSelectedImgFolder(folder.ID, 'folder')}>{folder.Name}</div>
            </div>
            <div id="folderContent">
            {insertImgs(folder.ID, ((index+2) /2))}
            {insertFolder(folder.ID, ((index+2)/2))}
            </div>
          </div>
         )}

        </>
        ))
      )
      
    }

  }

  const renderPlans = () =>{

    return (
    <>
    {plans.map((plan , i) =>(
      minimize.includes(plan.ID) ? (
        <div id='plan' key={i}>
        <div id="planName">
          <div id="minimize" onClick={() => handleMinimize(plan.ID)}>
            <img src={maximizeWindow} alt="maximizeWindow" /></div>
          <div id='line'><div></div></div>
          <div id="folderIcon"><img src={project} alt="project" /></div>
          <div id='name' 
          style={{backgroundColor: selectImgFolder.id === plan.ID ? '#f4c24f' : ''}}
          onClick={() => handelSelectedImgFolder(plan.ID, 'plan')}>
            {renameMode && selectImgFolder.id === plan.ID ? 
            (<input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />) : 
            (plan.Name)}
          </div>
        </div>
      </div>

      ) : (
        <div id='plan' key={i}>
          <div id="planName">
            <div id="minimize" onClick={() => handleMinimize(plan.ID)}>
              <img src={minimizeWindow} alt="minimizeWindow" /></div>
            <div id='line'><div></div></div>
            <div id="folderIcon"><img src={project} alt="project" /></div>
            <div id='name'
            onClick={() => handelSelectedImgFolder(plan.ID, 'plan')}
            style={{backgroundColor: selectImgFolder.id === plan.ID ? '#f4c24f' : ''}}
            >
            {renameMode && selectImgFolder.id === plan.ID ? 
            (
            <>
            <input id='renameInput' ref={inputRef} type="text" value={renameValue} onChange={handelRenameValueChange} />
            </>
            ) : 
            (plan.Name)}
            </div>
          </div>
          <div id="planContent">
            <div id="planContentLine" style={{paddingBottom: showModeImg ? '88px' : '13px' }}>
              <div></div>
            </div>
            <div id="planContentContent">
              {insertFolder(plan.ID, (0))}
              {insertImgs(plan.ID, (0))}
            </div>

          </div>
        </div>
      )
    ))}
    {renderNewFolder()}
    </>
  )
  }


  const renderTop = () =>{
    return(
      <>

        <div id="pName">
          Pages, Bookmarks
        </div>
        <div id="topContent">
          <div id="tools">
            <div id='tool'>
              <div id="ToolsIcon" onClick={CreateNewFolder}>
                <img id='newFolder' src={newFolder} alt="ruler"/>
              </div>
            </div>
            <div id='tool'>
              <div id="ToolsIcon" onClick={handelShowModeImg}>
                <img src={showModeImg ? showAsImg : showAsList} alt="change"/>
              </div>
            </div>
            <div id='tool'>
              <div id="ToolsIcon" onClick={handelRenameMode}>
                <img id='rename' src={rename} alt="rename"/>
              </div>
            </div>
            <div id='tool'>
              <div id="ToolsIcon" onClick={handelMoveMode}>
                <img id='moveToFolder' src={moveToFolder} alt="moveToFolder"/>
              </div>
            </div>
            <div id='tool'>
              <div id="ToolsIcon" onClick={handelDeleteMode}>
                <img  id='deleteFile' src={deleteIcon} alt="deleteIcon"/>
              </div>
            </div>
            <div id='tool'>
              <div id="ToolsIcon" onClick={handelDuplicate}>
                <img  id='duplicate' src={duplicate} alt="duplicate"/>
              </div>
            </div>
            
          </div>
          {moveMode && (
            <div id="moveTo">
              <select id='moveToSelect' onChange={handleSelectChange} value={selectedFolder}>
                <option id='moveToOption' value="">Select Folder</option>
                {plans.map((plan, i) => (
                  selectImgFolder.id !== plan.ID && (
                    <option id='moveToOption' key={i} value={plan.ID}>{plan.Name}</option>
                  )

                ))}
                {folders.map((folder, i) => (
                  selectImgFolder.id !== folder.ID && (
                    <option id='moveToOption' key={i} value={folder.ID}>{folder.Name}</option>
                  )
 
                ))}
              </select>
              <div id="buttonMove" onClick={moveTo}>Move</div>
            </div>
          )}

          {(deleteMode && selectImgFolder.id.length > 0) && (

              selectImgFolder.type === 'plan' ? (
                <div id="deleteDiv">
                  <div id="msg">
                    You want to delete the plan?
                  </div>
                  <div id="deleteButton">
                    <div id="delete" onClick={() => deleteFile('delete')}>Delete</div>
                    <div id="cancel" onClick={() => deleteFile('cancel')}>Cancel</div>
                  </div>
                </div>
              ):selectImgFolder.type === 'folder' ? (
                <div id="deleteDiv">
                  <div id="msg">
                    The folder contain a files, how you like to delete it?
                  </div>
                  <div id="deleteButton">
                    <div id="delete" onClick={() => deleteFile('oFolder')}>O/ Folder</div>
                    <div id="delete" onClick={() => deleteFile('wFiles')}>W/ files</div>
                    <div id="cancel" onClick={() => deleteFile('cancel')}>Cancel</div>
                  </div>
                </div>
              ):selectImgFolder.type === 'img' && (
                <div id="deleteDiv">
                  <div id="msg">
                  You want to delete the selected page?
                  </div>
                  <div id="deleteButton">
                    <div id="delete" onClick={() => deleteFile('delete')}>Delete</div>
                    <div id="cancel" onClick={() => deleteFile('cancel')}>Cancel</div>
                  </div>
                </div>
              )
          )}

        </div>
      </>
    )
  }


  return (
    <>

    <div id="projectFiles" 
    style={{
      height: closeProjectFiles ? '34px': ''
    }}
    >
      <div id="closeProjectFiles"
      onClick={handelCloseProjectFiles}>
        {closeProjectFiles ? (<img src={slideDownArrow} alt="openArrow" />):(<img src={slideUpArrow} alt="closeArrow" />) }
      </div>
      <div id="projectFileContent">
        <div id="top">
          {renderTop()}
        </div>
        <div id="plans">
          
          {plans.length > 0 ? (
            renderPlans()
          ):(
            <div id="loading">
              <ThreeDot color="#F07E22" size="small" text="" textColor="" />
            </div>
          )}
        </div>

      </div>

    </div>
    </>
  );
}


export default ProjectFiles;
