import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import chevronDown from '../../assetsTakeOff/CanvasTools/chevronDown.png';
import chevronRight from '../../assetsTakeOff/CanvasTools/chevronRight.png';
import pen from '../../assetsTakeOff/CanvasTools/pen.png';
import chevronLeft from '../../assetsTakeOff/CanvasTools/chevronLeft.png';
import VerticalMenu from '../../assetsTakeOff/CanvasTools/VerticalMenu.png';

const Group = (
  {
    yourDivisions,
    csiDivisions,
    handelSetCreateNewGroupOpen,
    CreateNewGroupRef,
    selectedGroup,
    handelSelectedGroup,
    setYourDivisions,
    setCsiDivisions
  }
) =>{

  const [openSelectGroup, setOpenSelectGroup] = useState(false)
  const [selectCsiDivisions , setSelectCsiDivisions] = useState(false);
  const [selectOwnDivisions , setSelectOwnDivisions] = useState(false);
  const [lastDivision, setLastDivision] = useState('')
  const [visibleGroups, setVisibleGroups] = useState({});
  const [openMenuIndex, setOpenMenuIndex] = useState({index: '', name: ''});

  const groupRef = useRef(null)

  

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!groupRef.current.contains(e.target) && 
      (!CreateNewGroupRef.current || !CreateNewGroupRef.current.contains(e.target))) { 
        if(e.target.className !== 'itIN'){
          setOpenSelectGroup(false);
          setSelectCsiDivisions(false);
          setSelectOwnDivisions(false);
          setVisibleGroups({ state: false, name: '', groups: [] });
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [CreateNewGroupRef]);

  const handelSelectDivision = (type) =>{
    if(type === 'CSI'){
      setSelectCsiDivisions((prevSelect) => !prevSelect)
      setSelectOwnDivisions(false)
      setVisibleGroups({ state: false });
    }
    else{
      setSelectOwnDivisions((prevSelect) => !prevSelect)
      setSelectCsiDivisions(false)
      setVisibleGroups({ state: false});
    }
  }
  const handelVisibleGroups= (division, lastDivision) =>{
    setVisibleGroups({
      state: true,
      category: division.Name,
      groups: division.Groups,
      categoryType: lastDivision
    })
    setLastDivision(lastDivision)
    setSelectOwnDivisions(false)
    setSelectCsiDivisions(false)

  }
  const handelGoBackToLastDivision = () =>{
    if(lastDivision === 'CSI'){
      setVisibleGroups({
        state: false
      })
      setSelectOwnDivisions(false)
      setSelectCsiDivisions(true)
    }else{
      setVisibleGroups({
        state: false
      })
      setSelectOwnDivisions(true)
      setSelectCsiDivisions(false)
    }
  }

  const handelOpenSelectGroup = () =>{
    setOpenSelectGroup((prevClick) => !prevClick)
  }

  const handelSelectedGroupInGroupStage = (visibleGroups, group) =>{
    const sendData = {
      group: group, 
      category: visibleGroups.category, 
      categoryType: visibleGroups.categoryType
    }
    handelSelectedGroup(sendData);
    handelOpenSelectGroup()
    setSelectOwnDivisions(false)
    setSelectCsiDivisions(false)
    setVisibleGroups({ state: false });
  }


  const handleMenuClick = (index, name) => {
    if (openMenuIndex.index === index) {
      setOpenMenuIndex({index: '', name: ''}); // Close the menu if the same icon is clicked again
    } else {
      setOpenMenuIndex({index: index, name: name}); // Open the menu for the clicked icon
    }
  };
  const handleDeleteCAG= () => {
    if (openMenuIndex.index === 'category') {
      setOpenMenuIndex({index: '', name: ''});
      const division = yourDivisions.find((div) => div.Name === openMenuIndex.name)
      let updatedYourDivisions;
      if(division && division.Groups.length > 0){
        const individualGroups = yourDivisions.find((div) => div.Name === 'Individual')

        if(individualGroups){
          const updatedGroups = [...individualGroups.Groups, ...division.Groups];
          updatedYourDivisions = yourDivisions.map(div => 
            div.Name === 'Individual' ? { ...div, Groups: updatedGroups } : div)

        }else{
          updatedYourDivisions = [
            ...yourDivisions,
            { Name: 'Individual', Groups: division.Groups }
          ];
        }
        updatedYourDivisions = updatedYourDivisions.filter(div => div.Name !== openMenuIndex.name);
        setYourDivisions(updatedYourDivisions);        
      }else{
        updatedYourDivisions = yourDivisions.filter((div) => div.Name !== openMenuIndex.name)
        setYourDivisions(updatedYourDivisions); 
      }
      axios.post(`https://app.api.scae.co/api/management/deleteCategory/${openMenuIndex.name}`);
      handelGoBackToLastDivision()
    } else {
      if(visibleGroups.categoryType === 'your'){
        const updatedYourDivisions = yourDivisions.map(div => {
          return {
            ...div,
            Groups: (div.Groups || []).filter(group => {
              return group !== openMenuIndex.name;
            })
          };
        });

        setYourDivisions(updatedYourDivisions);
      }
      else{
        const updatedCSIDivisions = csiDivisions.map(div => {
          return {
            ...div,
            Groups: (div.Groups || []).filter(group => {
              return group !== openMenuIndex.name;
            })
          };
        });
        setCsiDivisions(updatedCSIDivisions);
      }
      setVisibleGroups((prevVisibleGroups) => (
        {
        ...prevVisibleGroups,
          groups: (prevVisibleGroups.groups || []).filter(group => {
          return group !== openMenuIndex.name;
        })
        }
      ))
      axios.post(`https://app.api.scae.co/api/management/deleteGroup/${openMenuIndex.name}`);

    }
    setOpenMenuIndex({index: '', name: ''});
  };



  return (
    <div id="group" ref={groupRef}>
      <div id="GName">
        Group
      </div>
      <div id="groupBar" onClick={handelOpenSelectGroup}>
        <div id="selectedGroup">
          {selectedGroup.category !== '' &&(
            <>
            <span>{selectedGroup.category}</span>
            <span>/</span>
            </>
          )}

          <span>{selectedGroup.group}</span>
        </div>
          <img src={chevronDown} alt="chevronDown" />
      </div>
      {openSelectGroup && (
      <div id="groupMenu">
        <div id="groupMenuSelectedGroup">
          <img src={pen} alt="pen" />
          Individual
        </div>
        <div id="top">
          <div onClick={() => handelSelectDivision('CSI')}>CSI Division</div>
          <div onClick={() => handelSelectDivision('Your')}>Your Division</div>
        </div>

        {selectCsiDivisions && (
          <div id="menu">
            {csiDivisions.map((division, index) => (
              <div key={index} onClick={() => handelVisibleGroups(division, 'CSI')} className='itIN'>
                <div className='itIN' > 
                  <div className='itIN'>{division.Name}</div>
                    ({division.Groups.length})
                </div>
                <img src={chevronRight} alt="" className='itIN'/>
              </div>
            ))}
          </div>
          )}
        {selectOwnDivisions && (
          <div id="menu" >
            {yourDivisions.map((division, index) => (
              <div key={index} onClick={() => handelVisibleGroups(division, 'your')} className='itIN' >
                <div className='itIN' > 
                  <div className='itIN'>{division.Name}</div>
                    ({division.Groups.length})
                </div>
                <img src={chevronRight} alt="" className='itIN'/>
              </div>
            ))}
          </div>
        )}

        {visibleGroups.state && (
          <>
          
          <div id="division" className='itIN'>
            <img src={chevronLeft} alt="chevronLeft" className='itIN' onClick={handelGoBackToLastDivision}/>
            <div className='itIN' onClick={handelGoBackToLastDivision}>{visibleGroups.category}</div>
            {visibleGroups.categoryType === 'your' && (
              <img 
              src={VerticalMenu} 
              alt="VerticalMenu" 
              className='itIN'
              onClick={() => handleMenuClick('category', visibleGroups.category)}
            />
            )}
            {openMenuIndex.index === 'category'&& (
                <div id="smallSideMenu" className='itIN'>
                  <div className='itIN' onClick={handleDeleteCAG}>Delete</div>
                </div>
            )}
          </div>



          <div id="menu" style={{borderTop: '2px solid #E2E8F0'}} className='itIN'>
            
            
          {visibleGroups.groups && (
            visibleGroups.groups.map((group, index) => (
              <div key={index} 
                className='itIN'
                id='visMenuDiv'

              >
                <div className='itIN' onClick={() => handelSelectedGroupInGroupStage(visibleGroups, group)}>
                  <div className='itIN'>{group}</div>
                </div>
                <img 
                  src={VerticalMenu} 
                  alt="VerticalMenu" 
                  className='itIN'
                  onClick={() => handleMenuClick(index, group)}
                />
                {openMenuIndex.index === index && (
                <div id="smallSideMenu" className='itIN'>
                  <div className='itIN' onClick={handleDeleteCAG}>Delete</div>
                </div>
                )}

              </div>
            )))}
          </div>
          </>
        )}
        <div id="bottomButton">
          <div onClick={handelSetCreateNewGroupOpen}>Create New Group</div>
        </div>


      </div>
      )}
    </div>
  )

}

export default Group