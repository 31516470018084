import React, {Component} from "react";
import '../assets/AddForms.css';
import $ from "jquery";
import axios from 'axios';
import Cookies from 'js-cookie';


class AddForms extends Component {

  constructor(props){
    super(props);
    this.state ={
      formData: {
        ProjectName: '',
        DueDate: '',
        Location: '',
        ConstructionType: '',
        ProjectSF: '',
        CompanyName: '',
        PMName: '',
        Phone: '',
        Email: '',
        Plans: ''
      },
      addProjectFormClick: false,
      addCompanyFormClick: false
    }
    this.initialState = this.state
    this.initialFormData = this.state.formData;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addFormClick !== this.props.addFormClick) {
      if(this.props.addFormClick === 'AddProject'){
        this.setState({
          addProjectFormClick: true,
          addCompanyFormClick: false,
          formData: this.initialFormData
        })
  
      }else{
        this.setState({
          addProjectFormClick: false,
          addCompanyFormClick: true,
          formData: this.initialFormData
        })
      }
    }
    else{
      if(this.props.addFormClick === 'AddProject' && this.state.addProjectFormClick === false){
        this.setState({
          addProjectFormClick: true,
          addCompanyFormClick: false,
          formData: this.initialFormData
        })
  
      }
      else if(this.props.addFormClick === 'AddCompany' && this.state.addCompanyFormClick === false){
        this.setState({
          addProjectFormClick: false,
          addCompanyFormClick: true,
          formData: this.initialFormData
        })
  
      }
    }
  }

  handleChange = (event) =>{
    const{name, value} = event.target;
    this.setState((prevState) =>({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }))
  }

  handelSubmit = async () =>{
    const {addProjectFormClick, formData} = this.state;
    const UserId = Cookies.get('User_ID')
    if(addProjectFormClick === true){
      try{
        const res = await axios.post('https://app.api.scae.co/api/addProjectData',{formData, UserId})

        if(res.status === 200){
          this.setState({
            formData: this.initialFormData
          })
          alert(res.data)  
        }

      }
      catch (error){
        console.error(error);
      }
    }
    else{
      try{
        const res = await axios.post('https://app.api.scae.co/api/addCompanyData',{formData, UserId})

        if(res.status === 200){
          this.setState({
            formData: this.initialFormData
          })
          alert(res.data)  
        }

      }
      catch (error){
        console.error(error);
      }
    }

  }

  handelCloseForm = () =>{
    this.setState(this.initialState)
    $('#Projects-main-page > #add_forms').hide();
    window.location.reload();
  }

  renderAddProjectForm(){
    const{ formData } = this.state;
    return(
      <div id="Add-Project-form">
        <form>
          <div id="Project-details">
            <label>Project Name</label><br/>
            <input 
              type="text" 
              name='ProjectName' 
              placeholder="Project Name"
              value={formData.ProjectName}
              onChange={this.handleChange} 
              required/>

            <label>Due Date</label><br/>
            <input 
              type="date" 
              name='DueDate'
              placeholder="Due Date" 
              value={formData.DueDate}
              onChange={this.handleChange} 
              required/>

            <label>Location</label><br/>
            <input 
              type="text" 
              name='Location'
              placeholder="Location"
              value={formData.Location}
              onChange={this.handleChange} />

            <label>Construction Type</label><br/>
            <input 
              type="text" 
              name='ConstructionType'
              placeholder="Construction Type" 
              value={formData.ConstructionType}
              onChange={this.handleChange}/><br/>

            <label>Project SF</label><br/>
            <input 
              type="text" 
              name='ProjectSF'
              placeholder="Project SF" 
              value={formData.ProjectSF}
              onChange={this.handleChange}/>

          </div>
          <div id="GC-Information">
            <label>Company Name</label><br/>
            <input 
              type="text" 
              name='CompanyName'
              placeholder="Company Name"
              value={formData.CompanyName}
              onChange={this.handleChange}
              required/>

            <label>PM Name</label><br/>
            <input 
              type="text" 
              name='PMName'
              placeholder="PM Name"
              value={formData.PMName}
              onChange={this.handleChange}/>

            <label>Phone</label><br/>
            <input 
              type="text" 
              name='Phone'
              placeholder="Phone"
              value={formData.Phone}
              onChange={this.handleChange}/>

            <label>Email</label><br/>
            <input 
              type="text" 
              name='Email'
              placeholder="Email"
              value={formData.Email}
              onChange={this.handleChange}/><br/>

            <label>Plans</label><br/>
            <input 
              type="text" 
              name='Plans' 
              placeholder="Plans"
              value={formData.Plans}
              onChange={this.handleChange}/><br/>


          </div>
          <div id="add-to-DB-button">
            <div onClick={this.handelSubmit} >SEND</div>
            <div onClick={this.handelCloseForm}>CLOSE</div>
          </div>
        </form>
      </div>
    )
  }

  renderAddCompanyForm(){
    const{ formData } = this.state;
    return(
      <div id="Add-GC-form">
        <form >
            <div id="GC-Information">
              <label>Company Name</label><br/>
              <input 
                type="text" 
                name='CompanyName' 
                placeholder="Company Name" 
                value={formData.CompanyName}
                onChange={this.handleChange}
                required/>

              <label>PM Name</label><br/>
              <input 
                type="text" 
                name='PMName' 
                placeholder="PM Name"
                value={formData.PMName}
                onChange={this.handleChange}/>

              <label>Phone</label><br/>
              <input 
                type="text" 
                name='Phone' 
                placeholder="Phone"
                value={formData.Phone}
                onChange={this.handleChange}/>

              <label>Email</label><br/>
              <input 
                type="text" 
                name='Email' 
                placeholder="Email"
                value={formData.Phone}
                onChange={this.handleChange}/><br/>
            </div> 
            <div id="add-to-DB-button">
              <div onClick={this.handelSubmit} >SEND</div>
              <div onClick={this.handelCloseForm}>CLOSE</div>
          </div>
        </form>
      </div>
    )
  }


  render(){
    const {addProjectFormClick, addCompanyFormClick} = this.state
     return(
        <div id="add_forms">
          {addProjectFormClick &&(
            this.renderAddProjectForm()
          )}

          {addCompanyFormClick &&(
            this.renderAddCompanyForm()
          )}

        </div>
     );
  }
}


export default AddForms;