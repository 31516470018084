import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { Stage, Layer, Circle,  Line as KonvaLine, Rect, Image as KonvaImage} from 'react-konva';
import '../assetsTakeOff/Canvas.css';
import {Line} from './tools/line';
import {Rectangle} from './tools/rectangle';
import { Polygon } from './tools/polygon';
import { Count } from './tools/count';
import { Select } from './tools/select';
import { ThreeDot } from 'react-loading-indicators';


const Canvas = ({
  zoom, 
  drawMode,
  lineSize,
  scaleRatio,
  selectedColor,
  selectedGroup,
  setAllLines,
  AllLines,
  setRectangles,
  rectangles,
  polygons,
  setPolygons,
  AllCounts,
  setAllCounts,
  selectMeasureDrawing, 
  setSelectMeasureDrawing,
  pageInfo,
  pageId,
  image,
  setImage

  }) => {
  const [ppi, setPpi] = useState(28);

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [mouseMeasurementShow, setMouseMeasurementShow] = useState(false);
  const [mouseMeasurementNumber, setMouseMeasurementNumber] = useState(0)
  const [imageSize, setImageSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const stageRef = useRef(null);
  const layerRef = useRef(null);
  const canvasSectionRef = useRef(null);
  const { projectId } = useParams();

  const {
    handleMouseDownLine,
    handleMouseMoveLine,
    handleMouseUpLine,
    line,
    moveLine,
    ExitDrawingLine,
    finishDrawingLine

  } = Line(zoom,lineSize,selectedColor, selectedGroup, ppi, setAllLines, AllLines, projectId, pageId);

  const {
    handleMouseMoveRectangle,
    handleMouseUpRectangle,
    handleMouseDownRectangle,
    currentRectangle
  } = Rectangle(zoom,lineSize,selectedColor, selectedGroup, ppi, rectangles, setRectangles, projectId, pageId);

  const {
    handleMouseDownPolygon,
    handleMouseMovePolygon,
    finishDrawingPolygon,
    currentPolygon,
    ExitDrawingPolygon
  } = Polygon(zoom,lineSize,selectedColor, selectedGroup, ppi, polygons, setPolygons, projectId, pageId);

  const {
    handleMouseDownCount,
    ExitDrawingCount,
    finishDrawingCount,
    currentCount

  } = Count(zoom,lineSize, selectedColor, selectedGroup, AllCounts, setAllCounts, projectId, pageId);

  const {
    handelSelectDrawing,
    handelDeleteSelect
  } = Select(drawMode, selectMeasureDrawing, setSelectMeasureDrawing, AllCounts, setAllCounts, polygons, setPolygons, rectangles, setRectangles, setAllLines, AllLines);





  useEffect(() => {

    if (pageInfo) {

      const img = new Image();
      img.src = `data:image/png;base64,${pageInfo.data}`;
      img.onload = () => {
        const canvasSection = document.getElementById('canvasSection');
        const canvasSectionWidth = canvasSection.clientWidth;
        const scale = (canvasSectionWidth * 0.55) / img.width;
        setPpi((img.width * scale)/(pageInfo.widthInch)) 
        setImageSize({
          width: img.width * scale,
          height: img.height * scale
        });
        setImage(img);
      };
    }
  }, [pageInfo]);



  window.addEventListener('load', () => {
    const scrollContent = document.querySelector('#canvasSection');
    const canvas = document.querySelector('#canvas');
  
    scrollContent.scrollLeft = canvas.scrollWidth * 0.19;    
    scrollContent.scrollTop = canvas.scrollHeight * 0.16;
  });


  


  const handelMeasurementNumber = () =>{
    if (drawMode === 'line') {
      let number = 0
      if(line){
        number += line.measure
      }
      if(moveLine){
        number += moveLine.measure;
      }
      setMouseMeasurementNumber(((number * scaleRatio)/12).toFixed(2))
          
    } 
    else if (drawMode === 'rectangle') {
      if(currentRectangle){
        let number = currentRectangle.measure
        setMouseMeasurementNumber(((number * scaleRatio)/12).toFixed(2))
      }

    }
  }





  const handleMouseDown = (e) => {
    if (e.evt.button !== 0) return; 

    if (drawMode === 'line') {
      handleMouseDownLine(e);
      setMouseMeasurementShow(true)
    } 
    else if (drawMode === 'rectangle') {
      handleMouseDownRectangle(e)
      setMouseMeasurementShow(true)
    }
    else if (drawMode === 'polygon') {
      handleMouseDownPolygon(e);
    }
    else if (drawMode === 'count') {
      handleMouseDownCount(e);
    }
    if( drawMode !== 'select'){
      setSelectMeasureDrawing(null)
    }
    if (drawMode !== 'count') {
      finishDrawingCount();
    }
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    
    // Adjust for the zoom level
    setMousePosition({
      x: pointerPosition.x,
      y: pointerPosition.y
    });

    if (drawMode === 'line') {
      handleMouseMoveLine(e);
      handelMeasurementNumber()
    } 
    else if (drawMode === 'rectangle') {
      handleMouseMoveRectangle(e)
      handelMeasurementNumber()
    }
    else if (drawMode === 'polygon') {
      handleMouseMovePolygon(e);
    }
    
  };

  const handleMouseUp = (e) => {
    if (e.evt.button !== 0) return; 
    if (drawMode === 'line') {
      handleMouseUpLine(e);
    }
     else if (drawMode === 'rectangle') {
      handleMouseUpRectangle(e)
      setMouseMeasurementShow(false)
      setMouseMeasurementNumber(0)
    }
  };





  const handleMouseDownCanvas = (e) => {
    if (drawMode === 'move'){
      e.preventDefault();
      const canvasSection = canvasSectionRef.current;
      canvasSection.isDragging = true;
      canvasSection.startX = e.clientX + canvasSection.scrollLeft;
      canvasSection.startY = e.clientY + canvasSection.scrollTop;
    }

  };

  const handleMouseMoveCanvas = (e) => {
    if (drawMode === 'move'){
      const canvasSection = canvasSectionRef.current;
      if (!canvasSection.isDragging) return;
      canvasSection.scrollLeft = canvasSection.startX - e.clientX;
      canvasSection.scrollTop = canvasSection.startY - e.clientY;
    }
  };

  const handleMouseUpCanvas = () => {
    if (drawMode === 'move'){
      const canvasSection = canvasSectionRef.current;
      canvasSection.isDragging = false;
    }

  };


  useEffect(() => {

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        if(drawMode === 'polygon'){
          finishDrawingPolygon();
        }
        if(drawMode === 'line'){
          finishDrawingLine();
          setMouseMeasurementShow(false)
          setMouseMeasurementNumber(0)
        }
        if(drawMode === 'count'){
          finishDrawingCount();
        }
      }
      else if(e.key === 'Escape'){
        if(drawMode === 'polygon'){
          ExitDrawingPolygon();
        }
        if(drawMode === 'line'){
          ExitDrawingLine();
          setMouseMeasurementShow(false)
          setMouseMeasurementNumber(0)
        }
        if(drawMode === 'count'){
          ExitDrawingCount();
        }
      }
      else if(e.key === 'Delete' || e.key === 'Backspace'){
        if(drawMode === 'select' && selectMeasureDrawing){
          handelDeleteSelect()
        }
      }

    };
  

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);

    };
  }, [drawMode, 
    finishDrawingPolygon,
    ExitDrawingPolygon,
    finishDrawingLine,
    ExitDrawingLine,
    finishDrawingCount,
    ExitDrawingCount,
    handelDeleteSelect,
    selectMeasureDrawing
  ]);

  const handleContextMenu = (e) => {
    e.evt.preventDefault(); // Prevent default context menu
    if (drawMode === 'polygon') {
      finishDrawingPolygon();
    }
    if(drawMode === 'line'){
      finishDrawingLine();
      setMouseMeasurementShow(false)
      setMouseMeasurementNumber(0)
    }
    if(drawMode === 'count'){
      finishDrawingCount();
    }

  };





  const drawLine = (line) => {
    return line.points.map((point, i) => {
      return (
        (selectMeasureDrawing && selectMeasureDrawing.id === line.id) ?
        (
          <KonvaLine 
          key={i} 
          points={point} 
          stroke={'blue'} 
          strokeWidth={line.lineWidth}  
          draggable
          onClick={(e) => handelSelectDrawing(e,line.id, 0, 0, 'len')}
          />
          
        )
        :(
          <KonvaLine 
          key={i} 
          points={point} 
          stroke={line.color} 
          strokeWidth={line.lineWidth}  
          onClick={(e) => handelSelectDrawing(e,line.id, 0, 0, 'len')}
          />
        )

      );
    })     
    
  }
  const drawPolygon = (polygon, i) => {
    return (
      (selectMeasureDrawing && selectMeasureDrawing.id === polygon.id) ?
      ( <KonvaLine
        key={polygon.id}
        points={polygon.points}
        fill={'blue'}
        closed
        stroke={'white'}
        opacity={0.5}
        strokeWidth={polygon.lineWidth /2}
        draggable
        onClick={(e) => handelSelectDrawing(e,polygon.id, 0, 0, 'poly')}
      />
      )
      :(
        <KonvaLine
        key={polygon.id}
        points={polygon.points}
        fill={polygon.color}
        closed
        stroke={polygon.color}
        opacity={0.5}
        strokeWidth={polygon.lineWidth /2}
        onClick={(e) => handelSelectDrawing(e,polygon.id, 0, 0, 'poly')}
      />
      )

    )

  };

  const drawRectangle = (rectangle) => {
    return (
      (selectMeasureDrawing && selectMeasureDrawing.id === rectangle.id) ?
      (
        <Rect 
        key={rectangle.id} 
        x={rectangle.points.x} 
        y={rectangle.points.y} 
        width={rectangle.points.width} 
        height={rectangle.points.height} 
        fill={'blue'}
        stroke={'white'}
        strokeWidth={rectangle.lineWidth} 
        opacity={0.5}
        draggable
        onClick={(e) => handelSelectDrawing(e,rectangle.id, 0, 0, 'rec')}
      />
      ) 
      :(
        <Rect 
        key={rectangle.id} 
        x={rectangle.points.x} 
        y={rectangle.points.y} 
        width={rectangle.points.width} 
        height={rectangle.points.height} 
        fill={rectangle.color}
        stroke={rectangle.color}
        strokeWidth={rectangle.lineWidth} 
        opacity={0.5}
        onClick={(e) => handelSelectDrawing(e,rectangle.id, 0, 0, 'rec')}
      />
      )

    )
  };

  const drawCount = (count) =>{
    return count.points.map((point, i) => {
      return (
        ( selectMeasureDrawing && 
          selectMeasureDrawing.id === count.id &&
          selectMeasureDrawing.key === i
        ) ? (
          <Circle
          key={i}
          x={point.x}
          y={point.y}
          radius={count.lineWidth}
          fill={'white'}
          draggable
          stroke={'blue'} // Highlight selected circle
          strokeWidth={2}
          onClick={(e) => handelSelectDrawing(e,count.id, i, point, 'count')}
        />
        ):(
          <Circle
          key={i}
          x={point.x}
          y={point.y}
          radius={count.lineWidth}
          fill={count.color}
          onClick={(e) => handelSelectDrawing(e,count.id, i, point, 'count')}
        />
        )

      )
    })


  }









  return (
    <>


      <div 
        id="canvasSection" 
        ref={canvasSectionRef}
        onMouseDown={handleMouseDownCanvas}
        onMouseMove={handleMouseMoveCanvas}
        onMouseUp={handleMouseUpCanvas}
        onMouseLeave={handleMouseUpCanvas}
        >
        <div id="canvas">
          {pageInfo ? (
          <div id="page">
            <Stage
              width={imageSize.width * zoom}
              height= {imageSize.height * zoom}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onContextMenu={handleContextMenu}
              
              style={{ 
                transition: 'transform 5s liner',
                cursor: 
                drawMode === 'move' ? 'grab' : drawMode === 'select' ? 'default' : 'crosshair'
              }} 
              scaleX={zoom}
              scaleY={zoom}
              ref={stageRef}
              >
              <Layer ref={layerRef}>
                {image && 
                  (<KonvaImage 
                    image={image}
                    width={imageSize.width}
                    height={imageSize.height}
                    style={{ transition: 'transform 5s liner' }}
                  />
                )}
                {AllLines &&
                (AllLines.map((line) => (
                  drawLine(line)
                )))}
                  {line  &&
                  (drawLine(line)
                  )}


                {moveLine && (
                  <KonvaLine 
                  points={moveLine.points} 
                  stroke={moveLine.color} 
                  strokeWidth={moveLine.lineWidth /2}  />
                  
                )}

                {AllCounts && (AllCounts.map((count) => (
                  drawCount(count)
                ))
                )}
                {currentCount && (
                  drawCount(currentCount)
                )}

                {rectangles && rectangles.map((rect) => (
                  drawRectangle(rect)

                ))}
                {currentRectangle && (
                  drawRectangle(currentRectangle)
                )}
                {polygons && polygons.map((polygon, i) => (
                  drawPolygon(polygon, i)
                ))}
                {currentPolygon && (
                      <KonvaLine
                      points={currentPolygon.points}
                      fill={currentPolygon.color}
                      close={false}
                      stroke={currentPolygon.color}
                      strokeWidth={currentPolygon.lineWidth / 2}
                    />
                )}
              </Layer>
            </Stage>
            {mouseMeasurementShow && (
              <div
              id="follow-div"
              style={{
                position: 'absolute',
                left: `${mousePosition.x}px`,
                top: `${mousePosition.y}px`,
                height: '50px',
                //backgroundColor: 'red',
                borderRadius: '50%',
                pointerEvents: 'none',
                zIndex: 11111,
                padding: '20px',
                fontSize: '25px',
                fontWeight: 'bold'
                //transform: `scale(${1 / zoom})`, // Adjusting size based on zoom level
              }}
            >
              {mouseMeasurementNumber} ft
            </div>

            )}


          </div>
          ):(
            <div id="page"
            style={{
              width: '100%',
              height: '100%',
              //marginBottom: '200px',
              backgroundColor: '#ebebeb'
            }}>
              <div id="loading">
              <ThreeDot color="black" size="small" text="" textColor="" />
              </div>

            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default Canvas;
