import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import '../assetsManagement/reviewTakeOffs.css';
import axios from 'axios';
const XLSX = require('xlsx');


const ReviewTakeOffs = () => {


  const [convertData, setConvertData] = useState(null)
  const [info, setInfo] = useState(null)
  const navigate = useNavigate();

  const { projectId } = useParams();


  useEffect(() =>{

    const fetchData = async () =>{
      try{
        const resMeasureData = await axios.get(`https://app.api.scae.co/api/measurements/getMeasureForRT/${projectId}`);
        console.log(resMeasureData.data)
        if(resMeasureData.status === 200){
          setConvertData(transformData(resMeasureData.data));
          console.log(convertData)

        }
        const resultInfo = await axios.get(`https://app.api.scae.co/api/measurements/getInfoForRT/${projectId}`);
        setInfo(resultInfo.data[0])

      }
      catch{}
    }


    fetchData()



  },[projectId])



  const transformData = (data) => {
    const result = [];
  
    const processItems = (items) => {
      if (!items || items.length === 0) {
        return;
      }
  
      items.forEach((item) => {
        const pageName = item.planPageName;
        const categoryName = item.category;
        const groupName = item.group;
        const groupType = item.type;
        const measure = item.measure;
  
        // Find the page object, if it exists
        let page = result.find((p) => p.pageName === pageName);
        if (!page) {
          page = { pageName, pageData: [] };
          result.push(page);
        }
  
        // Find the category object within the page, if it exists
        let category = page.pageData.find((c) => c.categoryName === categoryName);
        if (!category) {
          category = { categoryName, categoryData: [] };
          page.pageData.push(category);
        }
  
        // Find the group object within the category by groupName and groupType
        let group = category.categoryData.find(
          (g) => g.groupName === groupName && g.groupType === groupType
        );
        if (!group) {
          group = { groupName, groupType, groupTotal: 0 };
          category.categoryData.push(group);
        }
  
        // Add the measure to the group's total
        group.groupTotal += Number(((measure * 48) / 12));
      });
    };
  
    // Process each array in the data object
    processItems(data.len);
    processItems(data.poly);
    processItems(data.rec);
    processItems(data.count);
  
    return result;
  };


  const transformDataToExcel = () =>{
    const workbook = XLSX.utils.book_new();
    const sheetData = [];
    const planName = info.PlanName.replace('.pdf', '')
    sheetData.push([]);
    sheetData.push(["", "", "", info.ProjectName, "", "", "", ""]);
    sheetData.push([]); // Adding an empty row for spacing
    // Header Row
    sheetData.push([planName, "Original Measurement", "Total Measurement", "Unit", "Wastage %", "Total (incl. wastage)", "Unit Cost", "Total Cost"]);
  
    // Iterate over the transformed data
    convertData.forEach(page => {
      // Add Page Name
      sheetData.push([]);
      sheetData.push([`Page: ${page.pageName}`]);

      // Iterate over categories
      page.pageData.forEach(category => {
        // Add Category Name
        sheetData.push([]); // Empty row for spacing
        if(category.categoryName !== ''){
          sheetData.push([category.categoryName]);
        }
        else{
          sheetData.push(['Uncategorized']);
        }

  
        // Iterate over groups
        category.categoryData.forEach(group => {
          // Add Group Data
          sheetData.push([
            group.groupName, 
            `${group.groupTotal.toFixed(2)} ft`, 
            `${group.groupTotal.toFixed(2)} ft`, 
            "ft", 
            "0%", 
            `${group.groupTotal.toFixed(2)}`, 
            "$0.00", 
            "$0.00"
          ]);
        });
  
        
      });
    });
  
    // Create worksheet and add to workbook
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    worksheet['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }, // Merge title across 8 columns
    ];

    worksheet['A1'].s = { alignment: { horizontal: "center", vertical: "center" } };

    worksheet['!cols'] = [
      { wch: 60 },  // Width for the first column (e.g., "Group Name")
      { wch: 18 },  // Width for the "Original Measurement" column
      { wch: 18 },  // Width for the "Total Measurement" column
      { wch: 5 },  // Width for the "Unit" column
      { wch: 18 },  // Width for the "Wastage %" column
      { wch: 18 },  // Width for the "Total (incl. wastage)" column
      { wch: 18 },  // Width for the "Unit Cost" column
      { wch: 18 }   // Width for the "Total Cost" column
    ];


  // Set row heights
  worksheet['!rows'] = [
    { hpt: 50 },  // Set row height for the first row (e.g., Title)
    { hpt: 18 },  // Set row height for the second row (empty spacing)
    { hpt: 24 },  // Set row height for the header row
    // Additional row heights can be set here, or leave undefined for default
  ];




  
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Write to file
    XLSX.writeFile(workbook, 'measurement.xls');
  };
  






  return (
    <div id="rtContainer">
      <div id="topNav">
        <div id="leftSide">
          <div>
            <span
            onClick={() => navigate(`/manage/project/${projectId}`)}
            >Management /</span>
            <span
            onClick={() => navigate(`/manage/project/${projectId}/takeoff/canvas/page`)}
            >TakeOffs /</span>
            <span>Review</span>
          </div>
        </div>
        <div id="rightSide">
          <div onClick={() => transformDataToExcel()}>Download XLS</div>
          <div onClick={() => navigate(`/manage/project/${projectId}/estimate`)}>Create Estimate</div>
        </div>
      </div>
      <div id="PlanName">
        {info &&
          info.PlanName.replace('.pdf', '')}
      </div>


      {convertData && 
      convertData.map((page, i) =>(
        <div id="takeOffsMeasure" key={i}>
          <div id="pageName">Page Name: {page.pageName}</div>
          <div id="tableHeader">
            <div>Name</div>
            <div>Unit</div>
            <div>Measurement</div>
            <div>Total</div>
          </div>
          {page.pageData.map((category, c) => (
            <div id="tableContent" key={c}>
              <div id="categoryName">
                {category.categoryName === '' ? 
                  'Uncategorized' :
                  category.categoryName
                }
              </div>
              {category.categoryData.map((group , g)=>(
                <div id="groups" key={g}>
                  <div></div>
                  <div>{group.groupName}</div>
                  <div>
                    {group.groupType === 'len' ? 'ft' :
                    group.groupType === 'rec' ? 'ft2' :
                    group.groupType === 'poly' ? 'ft2' :
                    group.groupType === 'cots' && 'count'
                    }
                  </div>
                  <div>{(group.groupTotal).toFixed(2)}</div>
                  <div>{(group.groupTotal).toFixed(2)}</div>
                </div>                
              ))}


            </div>
          ))}

        </div>
      ))} 


    </div>
  );
};

export default ReviewTakeOffs;