import axios from 'axios';
import {useState } from 'react';

export const Line = (
    zoom,
    lineSize, 
    selectedColor, 
    selectedGroup,
    ppi,
    setAllLines,
    AllLines,
    projectId, 
    pageID
  )=> {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [line, setLine] = useState(null);

  const [moveLine, setMoveLine] = useState(null);
  const[firstClickForDML, setFirstClickForDML] = useState(false)

  
  const finishDrawingLine = () =>{
    if(line){
      if(!AllLines){
        setAllLines([line]);
      }else{
        setAllLines((prevLines) => [...prevLines, line]);
      }
      const data = line
      axios.post(`https://app.api.scae.co/api/measurements/addMeasure`,{data})
      setLine(null);
      setIsDrawing(false);
      setFirstClickForDML(false)
      setMoveLine(null)
    }
  }

  const ExitDrawingLine = () =>{
    setLine(null);
    setIsDrawing(false);
    setFirstClickForDML(false)
    setMoveLine(null)
  }



  const handleMouseDownLine = (e) => {
    const { offsetX, offsetY } = e.evt;
    if(firstClickForDML === false){
      setStartPoint({ x: offsetX / zoom , y: offsetY / zoom });
      setFirstClickForDML(true)
    
    }
    else{
      setStartPoint({ x: offsetX / zoom, y: offsetY / zoom });
      if(!line){
        let lineNumber
        if(AllLines){
          lineNumber = AllLines.length + 1
        }else{
          lineNumber = 1
        }
        const newLine = {
            id: Math.floor(100000000000 + Math.random() * 900000000000),
            name: 'len '+lineNumber,
            category: selectedGroup.category,
            group: selectedGroup.group,
            type: 'len',
            points: [moveLine.points],
            lineWidth: (lineSize * 2) /2,
            color: selectedColor,
            measure: moveLine.measure,
            updatedLength: 1,
            projectId: projectId, 
            pageID: pageID
        }
        setLine(newLine);
      }
      else{
        const updatedLine = {
          ...line,
          measure: line.measure + moveLine.measure,
          points: [...line.points, moveLine.points]
        };
        setLine(updatedLine);
      }
    }
    
    setIsDrawing(true);
  };


  const handleMouseMoveLine = (e) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = e.evt;
    const lengthInInch = Math.sqrt(
      Math.pow((offsetX / zoom) - (startPoint.x), 2) +
      Math.pow((offsetY / zoom) - startPoint.y, 2)
    );
    setMoveLine({
      points:[
        startPoint.x,
        startPoint.y,
        offsetX / zoom,
        offsetY / zoom
      ],
      color: selectedColor,
      lineWidth: (lineSize * 2),
      measure: lengthInInch / ppi
    })
  };

  const handleMouseUpLine = (e) => {
    if (!isDrawing) return;
    setIsDrawing(true);
    
  };

  const handleMouseLeaveLine = (e) => {
    if (isDrawing) {
        setIsDrawing(false);
    }
  };
  


  return {
    handleMouseDownLine,
    handleMouseMoveLine,
    handleMouseUpLine,
    handleMouseLeaveLine,
    line,
    AllLines,
    moveLine,
    finishDrawingLine,
    ExitDrawingLine
  };

}