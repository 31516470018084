import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import '../assetsEstimate/Estimate.css';
import axios from 'axios';



const ReviewTakeOffs = () => {


  const [convertData, setConvertData] = useState(null)
  const [info, setInfo] = useState(null)
  const navigate = useNavigate();

  const { projectId } = useParams();


  useEffect(() =>{

    const fetchData = async () =>{
      try{
        const resMeasureData = await axios.get(`https://app.api.scae.co/api/measurements/getMeasureForRT/${projectId}`);
        console.log(resMeasureData.data)
        if(resMeasureData.status === 200){
          setConvertData(transformData(resMeasureData.data));
          console.log(convertData)

        }
        const resultInfo = await axios.get(`https://app.api.scae.co/api/measurements/getInfoForRT/${projectId}`);
        setInfo(resultInfo.data[0])

      }
      catch{}
    }


    fetchData()



  },[projectId])



  const transformData = (data) => {
    const result = [];
  
    const processItems = (items) => {
      if (!items || items.length === 0) {
        return;
      }
  
      items.forEach((item) => {
        const pageName = item.planPageName;
        const categoryName = item.category;
        const groupName = item.group;
        const groupType = item.type;
        const measure = item.measure;
  
        // Find the page object, if it exists
        let page = result.find((p) => p.pageName === pageName);
        if (!page) {
          page = { pageName, pageData: [] };
          result.push(page);
        }
  
        // Find the category object within the page, if it exists
        let category = page.pageData.find((c) => c.categoryName === categoryName);
        if (!category) {
          category = { categoryName, categoryData: [] };
          page.pageData.push(category);
        }
  
        // Find the group object within the category by groupName and groupType
        let group = category.categoryData.find(
          (g) => g.groupName === groupName && g.groupType === groupType
        );
        if (!group) {
          group = { groupName, groupType, groupTotal: 0 };
          category.categoryData.push(group);
        }
  
        // Add the measure to the group's total
        group.groupTotal += Number(((measure * 48) / 12));
      });
    };
  
    // Process each array in the data object
    processItems(data.len);
    processItems(data.poly);
    processItems(data.rec);
    processItems(data.count);
  
    return result;
  };









  return (
    <div id="rtContainer">
      <div id="topNav">
        <div id="leftSide">
          <div>
            <span
            onClick={() => navigate(`/manage/project/${projectId}`)}
            >Management /</span>
            <span
            onClick={() => navigate(`/manage/project/${projectId}/takeoff/canvas/page`)}
            >TakeOffs /</span>
            <span>Review</span>
          </div>
        </div>
        <div id="rightSide">
          <div onClick={() => navigate(`/manage/project/${projectId}/estimate`)}>Create Estimate</div>
        </div>
      </div>
      <div id="PlanName">
        {info &&
          info.PlanName.replace('.pdf', '')}
      </div>


      {convertData && 
      convertData.map((page, i) =>(
        <div id="takeOffsMeasure" key={i}>
          <div id="pageName">Page Name: {page.pageName}</div>
          <div id="tableHeader">
            <div>Name</div>
            <div>Unit</div>
            <div>Measurement</div>
            <div>Total</div>
          </div>
          {page.pageData.map((category, c) => (
            <div id="tableContent" key={c}>
              <div id="categoryName">
                {category.categoryName === '' ? 
                  'Uncategorized' :
                  category.categoryName
                }
              </div>
              {category.categoryData.map((group , g)=>(
                <div id="groups" key={g}>
                  <div></div>
                  <div>{group.groupName}</div>
                  <div>
                    {group.groupType === 'len' ? 'ft' :
                    group.groupType === 'rec' ? 'ft2' :
                    group.groupType === 'poly' ? 'ft2' :
                    group.groupType === 'cots' && 'count'
                    }
                  </div>
                  <div>{(group.groupTotal).toFixed(2)}</div>
                  <div>{(group.groupTotal).toFixed(2)}</div>
                </div>                
              ))}


            </div>
          ))}

        </div>
      ))} 


    </div>
  );
};

export default ReviewTakeOffs;