import React, {Component} from "react";
import '../assets/OverFlow.css';
import IconEmail from '../imgs/icons8-email-100.png';
import axios from 'axios';
import $ from 'jquery'; // Import jQuery
import IconFile from '../imgs/icons8-file-64.png';
import { formatNumberWithCommas } from './Function';



class OverFlow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectData :{
        projectInfo: [],
        trades: [],
        communications: [],
        msgToggle: false
      },
      mainButtons:{
        ButtonsClicked: false,
        UpdateButtonClicked: false,
        AddCommentButtonClicked: false,
        MoveToButtonClicked: false,
        DeleteButtonClicked: false
      },
      moveToButton:{
        MoveToValue: 'select',
      },
      updateTradeButton: {
        updateTradeValue: 'select',
        tradeSelect: 'Trade',
        addTradesLink: false,
        addProposalLink: false,
        addTrade: false,
        deleteTrade: false,
        proposalCostProfit: false,
        inputLink: '',
        isValidLinkInput: true,
        inputCost: '',
        isValidCostInput: true,
        isValidTradeSelect: true,
        inputComment: '',
        isValidComment: true
      },
      deleteButton: {
        inputConformDelete: '',
        isValidConformDelete: true,
        isProjectDeleted: false,
        projectDeletedMsg: ''
      }
    }

    this.initialState = this.state;
    this.initialMainButton = this.state.mainButtons;
    this.initialMoveToButton = this.state.moveToButton;
    this.initialUpdateTradeButton = this.state.updateTradeButton;
    this.initialDeleteButton = this.state.deleteButton;
  }

  componentDidUpdate(prevProps){
    if(prevProps.SelectedProject !== this.props.SelectedProject){
      this.fetchData(this.props.SelectedProject)
    }
  }


  fetchData = async (SelectedProject) =>{
    try{
      const res = await axios.post('https://app.api.scae.co/api/OverFlow',{Project_ID: SelectedProject.Project_ID, Company_ID: SelectedProject.Company_ID})
      this.setState({
        projectData:  {
          projectInfo: res.data.projectInfo[0],
          trades: res.data.trades,
          communications: res.data.communications
        }
      });

    }
    catch(error){
      console.error(error)
    }
  }


  closeDiv (){
    $('#overflow_page').hide();
    this.setState(this.initialState);
  }

  openMsg = ()=>{
    this.setState( prevState => ({
      projectData:{
        ...prevState.projectData,
        msgToggle: !prevState.projectData.msgToggle
      }
    }))
  }
  createHtml = (projectInfo) => {
    let MsgContent = projectInfo.Msg
    MsgContent = MsgContent.replace(/<span id='pm-name'><\/span>/g, projectInfo.PM_Name);
    MsgContent = MsgContent.replace(/<span id="project"><\/span>/g, projectInfo.ProjectName)
    return {__html: MsgContent};
  }

  buttonsDivClose = () =>{
    this.setState({
      mainButtons:{...this.initialMainButton},
      moveToButton:{...this.initialMoveToButton},
      updateTradeButton:{...this.initialUpdateTradeButton}
    })
  }

  clickedUpdateButton = (button) =>{
    if(button === 'Update'){
      this.setState({
        mainButtons: {
          ...this.initialMainButton,
          ButtonsClicked: true,
          UpdateButtonClicked: true
        }
      })
    }
    if(button === 'Add_Comment'){
      this.setState({
        mainButtons: {
          ...this.initialMainButton,
          ButtonsClicked: true,
          AddCommentButtonClicked: true
        }
      })
    }
    if(button === 'Move-To'){
      this.setState({
        mainButtons: {
          ...this.initialMainButton,
          ButtonsClicked: true,
          MoveToButtonClicked: true
        }
      })
    }

    if(button === 'Delete'){
      this.setState({
        mainButtons: {
          ...this.initialMainButton,
          ButtonsClicked: true,
          DeleteButtonClicked: true
        }
      })
    }

  }

  updateSelectValueForMoveTo = (event) => {
    this.setState({
      moveToButton:{
        MoveToValue: event.target.value
      }
    })
  }

  updateSelectValueForTrade = (event) =>{
    if(event.target.value === 'Trade'){
      this.setState(prevState =>({
        updateTradeButton:{
          ...prevState.updateTradeButton,
          tradeSelect: event.target.value,
          isValidTradeSelect: false
        }
      }));
    }else{
      this.setState(prevState =>({
        updateTradeButton:{
          ...prevState.updateTradeButton,
          tradeSelect: event.target.value,
          isValidTradeSelect: true
        }
      }));
    }

  }

  updateSelectValueForUpdateTrade = (event) => {


    if(event.target.value === 'addTradesLink'){
      this.setState({
        updateTradeButton:{
          ...this.initialUpdateTradeButton,
          addTradesLink: true,
          updateTradeValue: event.target.value
        }
      })
    }
    else if(event.target.value === 'addProposalLink'){
      this.setState({
        updateTradeButton:{
          ...this.initialUpdateTradeButton,
          addProposalLink: true,
          updateTradeValue: event.target.value
        }
      })
    }
    else if(event.target.value === 'addTrade'){
      this.setState({
        updateTradeButton:{
          ...this.initialUpdateTradeButton,
          addTrade: true,
          updateTradeValue: event.target.value
        }
      })
    }
    else if(event.target.value === 'deleteTrade'){
      this.setState({
        updateTradeButton:{
          ...this.initialUpdateTradeButton,
          deleteTrade: true,
          updateTradeValue: event.target.value
        }
      })
    }
    else if(event.target.value === 'proposalCostProfit'){
      this.setState({
        updateTradeButton:{
          ...this.initialUpdateTradeButton,
          proposalCostProfit: true,
          updateTradeValue: event.target.value
        }
      })
    }
  }


  handelInputLinkChange = (event) => {
    const value = event.target.value
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValidLinkInput = urlPattern.test(value);
    this.setState(prevState =>({
      updateTradeButton:{
        ...prevState.updateTradeButton,
        inputLink: value,
        isValidLinkInput: isValidLinkInput
      }
    })
    );
  }
  handelInputCostChange = (event) =>{
    const value = event.target.value
    const urlPattern = /^\d+$/;
    const isValidCostInput = urlPattern.test(value);
    this.setState(prevState =>({
      updateTradeButton:{
        ...prevState.updateTradeButton,
        inputCost: value,
        isValidCostInput: isValidCostInput
      }
    })
    );
  }
  handelInputCommentChange =(event) =>{
    this.setState(prevState =>({
      updateTradeButton:{
        ...prevState.updateTradeButton,
        inputComment: event.target.value,
        isValidComment: true      
      }
    })
    );
  }
  handelInputConformDeleteChange = (event) =>{
    this.setState(prevState => ({
      deleteButton: {
        ...prevState.deleteButton,
        inputConformDelete: event.target.value,
        isValidConformDelete: true
      }
    }))
  }

  submitButton = async (div) =>{
    if(div === 'moveTo'){

      try{
        axios.post('https://app.api.scae.co/api/OverFlow/moveTo',{
          moveTo: this.state.moveToButton.MoveToValue,
          projectId: this.state.projectData.projectInfo.Project_ID,
          companyId: this.state.projectData.projectInfo.Company_ID
        })
        window.location.reload();
      }
      catch(error){
        console.error(error)
      }
    }
    else if (div === 'addTradesLink' || div === 'addProposalLink'){
      const {inputLink,isValidLinkInput } = this.state.updateTradeButton;
      let Project_ID = this.state.projectData.projectInfo.Project_ID;
      let Company_ID = this.state.projectData.projectInfo.Company_ID;
      if(isValidLinkInput && inputLink !==''){

        try{
          const res = await axios.post('https://app.api.scae.co/api/OverFlow/addTradeOrProposalLink',{
            linkFor: div,
            link: inputLink,
            projectId: Project_ID,
            companyId: Company_ID
          })
          if(res.status === 200){
            this.setState(prevState =>({
              projectData:{
                ...prevState.projectData,
                projectInfo: res.data
              }
            }))
          }
        }
        catch(error){
          console.error(error)
        }
        
      }
    }

    else if (div === 'proposalCostProfit'){
      const {inputCost, isValidCostInput, tradeSelect } = this.state.updateTradeButton
      let Project_ID = this.state.projectData.projectInfo.Project_ID;

      if(isValidCostInput && inputCost !=='' && tradeSelect !== 'Trade'){
        try{
          const res = await axios.post('https://app.api.scae.co/api/OverFlow/updateProposalCostProfit',{
            tradeSelect: tradeSelect,
            inputCost: inputCost,
            projectId: Project_ID
          })
          if(res.status === 200){
            this.setState(prevState =>({
              projectData:{
                ...prevState.projectData,
                trades: res.data
              }
            }))
            this.setState(prevState =>({
              updateTradeButton:{
                ...prevState.updateTradeButton,
                inputCost: '',
              }
            }))
          }
        }
        catch(error){
          console.error(error)
        }
      }
      if(tradeSelect === 'Trade'){
        this.setState(prevState => ({
          updateTradeButton: {
            ...prevState.updateTradeButton,
            isValidTradeSelect: false
          }
        }))
      }

      if(inputCost ===''){
        this.setState(prevState => ({
          updateTradeButton: {
            ...prevState.updateTradeButton,
            isValidCostInput: false
          }
        }))
      }
    }

    else if (div === 'AddComment'){
      const {inputComment, isValidComment } = this.state.updateTradeButton;
      let Project_ID = this.state.projectData.projectInfo.Project_ID;
      let Company_ID = this.state.projectData.projectInfo.Company_ID;

      if(isValidComment && inputComment !==''){

        try{
          const res = await axios.post('https://app.api.scae.co/api/OverFlow/addComment',{
            comment: inputComment,
            projectId: Project_ID,
            companyId: Company_ID
          })
          if(res.status === 200){
            this.setState(prevState =>({
              projectData:{
                ...prevState.projectData,
                communications: res.data
              }
            }))
          }
        }
        catch(error){
          console.error(error)
        }
      }

      if(inputComment ===''){

        this.setState(prevState => ({
          updateTradeButton: {
            ...prevState.updateTradeButton,
            isValidComment: false
          }
        }))
      }
    }

    else if (div === 'deleteTrade'){
      const {isValidTradeSelect, tradeSelect } = this.state.updateTradeButton
      let Project_ID = this.state.projectData.projectInfo.Project_ID;

      if(isValidTradeSelect && tradeSelect !== 'Trade'){
        try{
          const res = await axios.post('https://app.api.scae.co/api/OverFlow/deleteTrade',{
            tradeSelect: tradeSelect,
            projectId: Project_ID
          })
          if(res.status === 200){
            this.setState(prevState =>({
              projectData:{
                ...prevState.projectData,
                trades: res.data
              }
            }))
          }
        }
        catch(error){
          console.error(error)
        }
      }
      if(tradeSelect === 'Trade'){
        this.setState(prevState => ({
          updateTradeButton: {
            ...prevState.updateTradeButton,
            isValidTradeSelect: false
          }
        }))
      }

    }

    else if (div === 'addTrade'){
      const {isValidTradeSelect, tradeSelect } = this.state.updateTradeButton
      let Project_ID = this.state.projectData.projectInfo.Project_ID;

      if(isValidTradeSelect && tradeSelect !== 'Trade'){
        try{
          const res = await axios.post('https://app.api.scae.co/api/OverFlow/addTrade',{
            tradeSelect: tradeSelect,
            projectId: Project_ID
          })
          if(res.status === 200 && res.data !== 'Trade In'){
            this.setState(prevState =>({
              projectData:{
                ...prevState.projectData,
                trades: res.data
              }
            }))
          }
        }
        catch(error){
          console.error(error)
        }
      }
      if(tradeSelect === 'Trade'){
        this.setState(prevState => ({
          updateTradeButton: {
            ...prevState.updateTradeButton,
            isValidTradeSelect: false
          }
        }))
      }

    }

    else if(div === 'DeleteTheProject'){
      const{inputConformDelete} = this.state.deleteButton
      const {ProjectName, Company_ID, Project_ID } = this.state.projectData.projectInfo

      console.log(Project_ID)

      if(inputConformDelete === ProjectName){

        const res = await axios.post('https://app.api.scae.co/api/projectDelete',{
          Company_ID: Company_ID,
          Project_ID: Project_ID
        })
        if(res.status === 200){
          this.setState({
            deleteButton: {
              inputConformDelete: '',
              isValidConformDelete: true,
              isProjectDeleted: true,
              projectDeletedMsg: 'Project Deleted'
            },
            mainButtons: {
              ...this.initialMainButton,
              ButtonsClicked: true
            }
          })
          setTimeout(() =>{
            window.location.reload()
          },1000)
        }
      }
      else{
        this.setState(prevState => ({
          deleteButton: {
            ...prevState.deleteButton,
            isValidConformDelete: false
          }
        }))
      }

    }
  }



  renderDivs(){
    const {projectInfo, trades, communications, msgToggle} = this.state.projectData;
    const {ButtonsClicked, UpdateButtonClicked, MoveToButtonClicked, AddCommentButtonClicked, DeleteButtonClicked} = this.state.mainButtons;

    const {addTradesLink, addProposalLink, addTrade, deleteTrade,proposalCostProfit, inputLink, isValidLinkInput, isValidCostInput, inputCost, isValidTradeSelect,inputComment,isValidComment  } = this.state.updateTradeButton;

    const {inputConformDelete, isValidConformDelete, isProjectDeleted, projectDeletedMsg} = this.state.deleteButton;

    const manageUrl = '/manage/project/'+projectInfo.Project_ID;

    let total = 0;
    if(Object.keys(projectInfo).length > 0){
      $('#overflow_page').show();

      return(
        <div id="overflow_page"
        style={{display:'block'}}>
          <div id="full_page">
            <div id="top">
                <div id="project_name">{projectInfo.ProjectName}</div>
                {projectInfo.Status === 'Submitted' ? (
                  <div id='date'>{projectInfo.Date_Submitted}</div>
                ):(
                  <div id='date'>{projectInfo.DueDate}</div>
                )}
                <div 
                id='close'
                onClick={() => this.closeDiv()}>X</div>
            </div>
            <div id="info">
              <div id="project_info">  
                <div id='header'>Project Info</div>
                <div id="name">
                  <div>Name :</div>
                  <div id="project_name">{projectInfo.ProjectName}</div>
                </div>
                <div id="dueDate">
                  <div>Due Date :</div>
                  <div id="project_due_date">{projectInfo.DueDate}</div>
                </div>

                <div id="location">
                  <div>Location :</div>
                  <div id="project_location">{projectInfo.Location}</div>
                </div>

                <div id="plans">
                  <div>Plans :</div>
                  <div id="projects_plans">
                    <a 
                    href={projectInfo.Plans} 
                    target='_blank'
                    rel="noreferrer">{projectInfo.Plans} </a>
                  </div>
                </div>
              </div>
              <div id="line"></div>
              <div id="company_info">

                <div id='header'>Company Info</div>
                <div id="name">
                  <div>Company :</div>
                  <div id="GC_name">{projectInfo.CompanyName}  </div>
                </div>

                <div id="PM">
                  <div>PM Name:</div>
                  <div id="PM-name">{projectInfo.PM_Name} </div>
                </div>
                <div id="phone">
                  <div>Phone :</div>
                  <div id="pm-phone">{projectInfo.Phone} </div>
                </div>
                <div id="email">
                  <div>Email :</div>
                  <div id="pm-email">{projectInfo.Email} </div>
                </div>
              </div>
            </div>
            <div id="line" className="info_line"></div>
            {trades.length > 0 && (
              <>
              <div id="trade">
              <div id="header">
                <div>Estimates 
                  {projectInfo.Trades_link !== '' &&(
                    <a href={projectInfo.Trades_link}  target="_blank" rel="noreferrer">
                      <img src={IconFile} alt=""/>
                    </a>
                  )}
                </div>
              </div>
              <div id="body">
                {trades.map((trade, i)=>(
                  <div key={i}>
                    <div></div>
                    <div>{trade.Trade}</div>
                  </div>
                ))}
              </div>
              </div>
              <div id="line" className="trade_line"></div>
              <div id="proposal">
                <div id="header">
                    <div>Proposals 
                    {projectInfo.Proposals_link !== '' &&(
                    <a href={projectInfo.Proposals_link}  target="_blank" rel="noreferrer">
                      <img src={IconFile} alt=""/>
                    </a>
                  )}
                    </div>
                    <div>Cost</div>
                    <div>Profit</div>
                    <div>Total</div>
                </div>
                <div id="body">
                  {trades.map((proposal, i)=> {
                    total += Number(proposal.Total)
                    return (
                      <div key={i}>
                      <div></div>
                      <div>{proposal.Trade}</div>
                      <div>$ {formatNumberWithCommas(Number(proposal.Total * 0.8))}</div>
                      <div>$ {formatNumberWithCommas(Number(proposal.Total * 0.2))}</div>
                      <div>$ {formatNumberWithCommas(Number(proposal.Total))}</div>
                    </div>
                    )

                  })}
                </div>
                {total > 0 && (
                    <div id="total">
                      <div>Total :</div>
                      <div>$ {formatNumberWithCommas(total*0.8)}</div>
                      <div>$ {formatNumberWithCommas(total*0.2)}</div>
                      <div>$ {formatNumberWithCommas(total)}</div>
                    </div>
                  )
                }

              </div>
              <div id="line" className="trade_line"></div>
              </>
            )}
            {communications.length > 0 && (
              <>
              <div id="Follow_up_Communication">
                <div id="header">Communication</div>
                <div id="body">
                  {communications.map((communication, i) => (
                    <div key={i}>
                      <div></div>
                      <div>{communication.FollowUP_Date}</div>
                      <div>{communication.FollowUP_comment}</div>
                    </div>

                  ))}
                </div>
              </div>
              <div id="line" className="FollowUP_line"></div>
              </>
            )}


            {!ButtonsClicked && (
              <div id="button-section">
                <div id="Move-To" onClick={() => this.clickedUpdateButton('Move-To')}>
                    <div>Move To</div>
                </div>

                <div id="Update" onClick={() => this.clickedUpdateButton('Update')}>
                    <div>Trades</div>
                </div>

                <div id="Add_Comment" onClick={() => this.clickedUpdateButton('Add_Comment')}>
                    <div>Add Comment</div>
                </div>
                <div id="Delete" onClick={() => this.clickedUpdateButton('Delete')}>
                    <div>Delete</div>
                </div>

                <div id="Takeoff">
                  <a href={manageUrl} target="_blank" rel="noopener noreferrer">TakeOff</a>
                </div>
              </div>
            )}

            {MoveToButtonClicked && (
              <div id="Update-section">
                <div>
                  <div id ="moveto-select" onChange={this.updateSelectValueForMoveTo}>
                    <select required>
                      <option defaultValue>Select</option>
                      <option value="Send">Send</option>
                      <option value="Work On">Work On</option>
                      <option value="Submitted">Submitted</option>
                      <option value="Awarded">Awarded</option>
                      <option value="Not Awarded">Not Awarded</option>
                      <option value="Not Send">Not Send</option>
                      <option value="Archive">Archive</option>
                    </select>
                  </div>
                </div>

                <div id="section-button">
                  <div id="submit" onClick={() => this.submitButton('moveTo')}>
                    <div>Submit</div>
                  </div>
                  <div id="section-close" onClick={this.buttonsDivClose}><div>Close</div></div>
                </div>
              </div>
            )}

            {UpdateButtonClicked && (
              <div id="Update-section">
                <div>
                    <div id="section-select" onChange={this.updateSelectValueForUpdateTrade}>
                      <select required>
                        <option defaultValue>Task</option>
                        <option value="addTradesLink">Add Trade Link</option>
                        <option value="addProposalLink">Add Proposal Link</option>
                        <option value="addTrade">Add Trade</option>
                        {trades.length > 0 && (
                          <>
                          <option value="deleteTrade">Delete Trade</option>
                          <option value="proposalCostProfit">Add Or Update Trade Total</option>
                          </>

                        )}

                      </select>
                    </div>
                    {(addTrade) &&(
                      <div id="trade-select" onChange={this.updateSelectValueForTrade}>
                        <select name="add-select" required
                        style={{ borderColor: isValidTradeSelect ? '' : 'red' }}>
                          <option defaultValue>Trade</option>
                          <option value="Demolition">Demolition</option>
                          <option value="Drywall & Framing">Drywall & Framing</option>
                          <option value="Acoustical">Acoustical</option>
                          <option value="Ceiling">Ceiling</option>
                          <option value="Finishes">Finishes</option>
                          <option value="Flooring">Flooring</option>
                          <option value="Wall Base">Wall Base</option>
                          <option value="Electrical">Electrical</option>
                          <option value="Plumbing">Plumbing</option>
                          <option value="Concert">Concert</option>
                        </select>
                      </div>
                    )}
                    {(deleteTrade || proposalCostProfit) &&(
                      <div id="trade-select" onChange={this.updateSelectValueForTrade}>
                        <select 
                        name="add-select"
                        required
                        style={{ borderColor: isValidTradeSelect ? '' : 'red' }}>
                          <option defaultValue>Trade</option>
                          {trades.map((trade, i)=>(
                            <option key={i} value={trade.Trade}>{trade.Trade}</option>
                          ))}
                        </select>
                      </div>
                    )}

                </div>

                {proposalCostProfit && (
                  <div id ="add_section_input">
                    <input 
                      type="text" 
                      placeholder="Add Total"
                      value={inputCost}
                      style={{ borderColor: isValidCostInput ? '' : 'red' }}
                      onChange={this.handelInputCostChange}/>
                      {!isValidCostInput && 
                      <p style={{ color: 'red' }}>Please enter a valid URL.</p>}
                      {!isValidTradeSelect && 
                      <p style={{ color: 'red' }}>Please Select Trade.</p>}
                  </div>
                )}
                {addTradesLink && (
                  <div id ="add_section_input">
                    <input 
                      type="text" 
                      placeholder="Add Trade Link"
                      value={inputLink}
                      style={{ borderColor: isValidLinkInput ? '' : 'red' }}
                      onChange={this.handelInputLinkChange}/>
                      {!isValidLinkInput && 
                      <p style={{ color: 'red' }}>Please enter a valid URL.</p>}
                  </div>
                )}
                {addProposalLink && (
                  <div id ="add_section_input">
                    <input 
                      type="text" 
                      placeholder="Add Proposal Link"
                      value={inputLink}
                      style={{ borderColor: isValidLinkInput ? '' : 'red' }}
                      onChange={this.handelInputLinkChange}/>
                    {!isValidLinkInput && 
                      <p style={{ color: 'red' }}>Please enter a valid URL.</p>}
                  </div>
                )}

                <div id="section-button">
                  {addTrade &&(
                    <div id="submit" onClick={() => this.submitButton('addTrade')}>
                      <div>Submit</div>
                    </div>
                  )}

                  {proposalCostProfit &&(
                    <div id="submit" onClick={() => this.submitButton('proposalCostProfit')}>
                      <div>Submit</div>
                    </div>
                  )}

                  {addTradesLink &&(
                    <div id="submit" onClick={() => this.submitButton('addTradesLink')}>
                      <div>Submit</div> 
                    </div>
                  )}

                  {addProposalLink &&(
                    <div id="submit" onClick={() => this.submitButton('addProposalLink')}>
                      <div>Submit</div>
                    </div>
                  )}

                  {deleteTrade &&(
                    <div id="submit" onClick={() => this.submitButton('deleteTrade')}>
                      <div>Submit</div>
                    </div>
                  )}

                  <div id="section-close" onClick={this.buttonsDivClose}><div>Close</div></div>
                </div>
              </div>
            )}
            
            {AddCommentButtonClicked && (
              <div id="Update-section">
                  <div id ="add_section_input">
                    <input 
                      type="text" 
                      placeholder="Add Comment"
                      value={inputComment}
                      style={{ borderColor: isValidComment ? '' : 'red' }}
                      onChange={this.handelInputCommentChange}/>

                  </div>
                  {!isValidComment && 
                      <p style={{ color: 'red' }}>Invalid input.</p>}

                <div id="section-button">
                  <div id="submit" onClick={() => this.submitButton('AddComment')}>
                    <div>Submit</div>
                  </div>
                  <div id="section-close" onClick={this.buttonsDivClose}><div>Close</div></div>
                </div>
              </div>
            )}

            {DeleteButtonClicked && (
              <div id="Update-section">
                <p style={{ color: 'black' , margin: '20px 0 0 0'}}>Type <i style={{ color: 'red'}}>Project Name</i> To Conform Delete The Project. </p>
                  <div id ="add_section_input">
                    <input 
                      type="text" 
                      value={inputConformDelete}
                      style={{ borderColor: isValidConformDelete ? '' : 'red' }}
                      onChange={this.handelInputConformDeleteChange}/>

                  </div>
                  {!isValidConformDelete && 
                      <p style={{ color: 'green' }}>Project Not Deleted.</p>}

                <div id="section-button">
                  <div id="submit" onClick={() => this.submitButton('DeleteTheProject')}>
                    <div>Submit</div>
                  </div>
                  <div id="section-close" onClick={this.buttonsDivClose}><div>Close</div></div>
                </div>
              </div>
            )}

            {isProjectDeleted && (
              <div id="Update-section">
                <p style={{ color: 'black' , margin: '20px 0 0 0'}}>{projectDeletedMsg}</p>
              </div>
            )}

          

            <div id="email-text">
              <div id="img">
                <img src={IconEmail} alt="IconEmail" onClick={this.openMsg}/>
              </div>
            {msgToggle && (
              <>
                <div id="line"></div>
                <div id="text">
                  <div dangerouslySetInnerHTML={this.createHtml(projectInfo)}/>
                  </div>
              </>
            )}
          </div>

          </div>
        </div>
      )
    }

  }


  render(){
    return(
      this.renderDivs()
    )
  }

}

export default OverFlow;