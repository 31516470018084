import React, {Component} from "react";
import '../assets/HomePage.css';
import axios from 'axios';
import Cookies from 'js-cookie';


class HomePage extends Component {

  constructor(props){
    super(props);
    this.state ={
      WorkOn: [],
      Submitted: [],      
      Awarded: [],   
      Send: [],   
      NotSend: [] 
    }
  }

  componentDidMount(){
    Cookies.set('User_ID', 'USERACC_4273', { expires: 30 });
    this.fetchData();
  }


  fetchData = async () => {
    try{
      const res = await axios.get('https://app.api.scae.co/api/get_projects_home_page')

      this.setState({
        WorkOn: res.data.WorkOn,
        Submitted: res.data.Submitted,      
        Awarded: res.data.Awarded,   
        Send: res.data.Send,   
        NotSend: res.data.NotSend
      })

    }
    catch (error){
      console.error(error);
    }
    
  }

  OverFlowPage = (Project_ID, Company_ID) => {
    this.props.OverFlowPageSelectedProject({Project_ID, Company_ID})
  }
  
  renderDivs(){
    const { WorkOn, Submitted,Awarded, Send, NotSend } = this.state;
    return(
      <div id="body">
        {WorkOn.length > 0 && (
          <div id="Work_On_Stage">
            <div id="top"><div id="text">Work On</div></div>
            <div id="body">
              {WorkOn.map((project, i) => (
                <div
                key={i}
                id={project.Project_ID}
                onClick={() => this.OverFlowPage(project.Project_ID, project.Company_ID)}>
                  <div id='name'>
                    {project.ProjectName}
 
                  </div>
                  <div id="date">
                    {project.DueDate}
                  </div>

                </div>
              ))}

            </div>
          </div>
        )}

        {Send.length > 0 && (
          <div id="Communication_send_stage">
            <div id="top"><div id="text">Send - Waiting Respond</div></div>
            <div id="body">
              {Send.map((project, i) => (
                <div
                key={i}
                id={project.Project_ID}
                onClick={() => this.OverFlowPage(project.Project_ID, project.Company_ID)}>
                  <div id='name'>
                    {project.ProjectName}
 
                  </div>
                  <div id="date">
                    {project.DueDate}
                  </div>

                </div>
              ))}

            </div>
          </div>
        )}

        {NotSend.length > 0 && (
          <div id="Communication_not_send_stage">
            <div id="top"><div id="text">Need To Communicate</div></div>
            <div id="body">
              {NotSend.map((project, i) => (
                <div
                key={i}
                id={project.Project_ID}
                onClick={() => this.OverFlowPage(project.Project_ID, project.Company_ID)}>
                  <div id='name'>
                    {project.ProjectName}
 
                  </div>
                  <div id="date">
                    {project.DueDate}
                  </div>

                </div>
              ))}

            </div>
          </div>
        )}

        {Submitted.length > 0 && (
          <div id="FollowUp">
            <div id="top"><div id="text">Need To Follow Up</div></div>
            <div id="body">
              {Submitted.map((project, i) => (
                <div
                key={i}
                id={project.Project_ID}
                onClick={() => this.OverFlowPage(project.Project_ID, project.Company_ID)}>
                  <div id='name'>
                    {project.ProjectName}
 
                  </div>
                  <div id="date">
                    {project.Date_Submitted}
                  </div>

                </div>
              ))}

            </div>
          </div>
        )}

        {Awarded.length > 0 && (
          <div id="Awarded">
            <div id="top"><div id="text">Awarded</div></div>
            <div id="body">
              {Awarded.map((project, i) => (
                <div
                key={i}
                id={project.Project_ID}
                onClick={() => this.OverFlowPage(project.Project_ID, project.Company_ID)}>
                  <div id='name'>
                    {project.ProjectName}
 
                  </div>
                  <div id="date">
                    {project.Data_Submitted}
                  </div>

                </div>
              ))}

            </div>
          </div>
        )}
      </div>
    );
  }

  render(){
    return (
      this.renderDivs()
    )

  }
}
export default HomePage;