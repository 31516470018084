import React, { Component } from 'react';
import Nav from './components/Nav';
import HomePage from './components/HomePage';
import OverFlow from './components/OverFlow';
import AddForms from './components/AddForms';
import Tables from './components/Tables';
import './assets/AllPages.css';
import Manage from './componentsManagement/Management'
import TakeOff from './componentsTakeOff/TakeOff';
import 'bootstrap/dist/css/bootstrap.min.css';
import Estimate from './componentsEstimate/Estimate';
import ReviewTakeOffs from './componentsManagement/ReviewTakeOffs';


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTable: null ,// State to store selected table info
      SelectedProject: null,
      addFormClick: null
    };
  }

  handleTableSelect = (selectedTable) => {
    this.setState({ selectedTable });
  };
  handelSelectedProject = (SelectedProject) =>{
    this.setState({ SelectedProject });
  }
  handleAddFromClick = (addFormClick) => {
    this.setState({ addFormClick: addFormClick });

  }


  renderHomePage(){
    const { selectedTable, SelectedProject, addFormClick } = this.state;
    return (
      <div>
        <Nav 
          onTableSelect={this.handleTableSelect}
          addFormClick={this.handleAddFromClick}

        />
        <main>
          <div id="Projects-main-page">
              <div id="header">
        
              </div>
              <HomePage OverFlowPageSelectedProject={this.handelSelectedProject}/>
              <AddForms addFormClick={addFormClick}/>
              <Tables selectedTable={selectedTable}/>
          </div>
          <OverFlow SelectedProject={SelectedProject}/>
        </main>
      </div>
    )
  }

  renderTakeOffPage(click){
    return (
      <div className="App">
        <div className="main-content">
          <Manage takeOffClick={click}/>
        </div>
      </div>
    )
  }

  renderUpload(){
    return (
      <>
      <TakeOff />
      </>
    )
  }

  renderReviewTakeOffs(){
    return (
      <>
      <ReviewTakeOffs />
      </>
    )
  }
  renderEstimate(){
    return (
      <>
      <Estimate />
      </>
    )
  }
  email(){
    return (
      <>
      <Estimate />
      </>
    )
  }

  render(){

     return(
        <Router>
          <Routes>
          <Route path="/email" element={this.email()} />
            <Route path="/" element={this.renderHomePage()} />
            <Route path="/manage/project/:id" element={this.renderTakeOffPage(false)} />
            <Route path="/manage/project/:id/takeoff" element={this.renderTakeOffPage(true)} />
            <Route path="/manage/project/:projectId/takeoff/canvas/page" element={this.renderUpload()} />
            <Route path="/manage/project/:projectId/takeoff/canvas/page/:pageName" element={this.renderUpload()} />
            <Route path="/manage/project/:projectId/review" element={this.renderReviewTakeOffs()} />
            <Route path="/manage/project/:projectId/estimate" element={this.renderEstimate()} />
          </Routes>
        </Router>

     );
  }
}


export default App;