import axios from "axios"


export const Select = (
  drawMode, selectMeasureDrawing, setSelectMeasureDrawing, AllCounts, setAllCounts, polygons, setPolygons, rectangles, setRectangles, setAllLines, AllLines
) => {


  const handelSelectDrawing = (e, id, key, point , type) =>{
    if(e.evt.button !==0) return
    if(drawMode === 'select'){
      if(type === 'count'){
        if(selectMeasureDrawing && selectMeasureDrawing.id === id && selectMeasureDrawing.key === key){
          setSelectMeasureDrawing(null)
        }else{
          setSelectMeasureDrawing({
            id: id,
            key: key,
            point: point,
            type: type
          })
        }
      }
      if(type === 'rec'){

        if(selectMeasureDrawing && selectMeasureDrawing.id === id){
          setSelectMeasureDrawing(null)
        }else{
          setSelectMeasureDrawing({
            id: id,
            key: key,
            point: point,
            type: type
          })
        }
      }
      if(type === 'len'){

        if(selectMeasureDrawing && selectMeasureDrawing.id === id){
          setSelectMeasureDrawing(null)
        }else{
          setSelectMeasureDrawing({
            id: id,
            key: key,
            point: point,
            type: type
          })
        }
      }
      if(type === 'poly'){

        if(selectMeasureDrawing && selectMeasureDrawing.id === id){
          setSelectMeasureDrawing(null)
        }else{
          setSelectMeasureDrawing({
            id: id,
            key: key,
            point: point,
            type: type
          })
        }
      }
    }
  } 

  const handelDeleteSelect = () =>{
    if(selectMeasureDrawing.type === 'count'){
      handelDeleteCount()
    }
    if(selectMeasureDrawing.type === 'rec'){
      handelDeleteRec()
    }
    if(selectMeasureDrawing.type === 'len'){
      handelDeleteLen()
    }
    if(selectMeasureDrawing.type === 'poly'){
      handelDeletePoly()
    }
  }


  const handelDeleteLen = () =>{
    let allLines  = AllLines.filter((line) => line.id !== selectMeasureDrawing.id);
    let measure =  AllLines.find((line) => line.id === selectMeasureDrawing.id)

    axios.post(`https://app.api.scae.co/api/measurements/deleteMesure`,{measure}) 
    setAllLines(allLines)
    setSelectMeasureDrawing(null)
  } 

  const handelDeletePoly = () =>{
    let allPoly  = polygons.filter((polygon) => polygon.id !== selectMeasureDrawing.id);
    let measure =  polygons.find((polygon) => polygon.id === selectMeasureDrawing.id)

    axios.post(`https://app.api.scae.co/api/measurements/deleteMesure`,{measure}) 
    setPolygons(allPoly)
    setSelectMeasureDrawing(null)
  } 

  const handelDeleteRec = () =>{
    let allRectangle  = rectangles.filter((rectangle) => rectangle.id !== selectMeasureDrawing.id);
    let measure =  rectangles.find((rectangle) => rectangle.id === selectMeasureDrawing.id)

    axios.post(`https://app.api.scae.co/api/measurements/deleteMesure`,{measure}) 
    setRectangles(allRectangle)
    setSelectMeasureDrawing(null)
  } 


  const handelDeleteCount = () => {
    let allcount  = AllCounts.filter((count) => count.id !== selectMeasureDrawing.id);
    let measure =  AllCounts.find((count) => count.id === selectMeasureDrawing.id)
    const newCountPoints = measure.points.filter((point) => point !== selectMeasureDrawing.point)
    if(newCountPoints.length > 0){
      measure = {
        ...measure,
        points: newCountPoints,
        measure: measure.measure - 1
      }
      allcount = [...allcount , measure]
      axios.post(`https://app.api.scae.co/api/measurements/deletePoint`,{measure}) 
    setAllCounts(allcount)
    setSelectMeasureDrawing(null)
    }
    else{
      axios.post(`https://app.api.scae.co/api/measurements/deleteMesure`,{measure}) 
      setAllCounts(allcount)
      setSelectMeasureDrawing(null)
    }
  }


  return {
    handelSelectDrawing,
    handelDeleteSelect
  };
}

