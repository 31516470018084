import React, {useState, useEffect}from 'react';
import '../assetsManagement/takeOff.css';
import axios from 'axios';
import { useParams } from 'react-router'



const TakeOff = ({handelUploadClick}) => {
  const[wantToDelete, setWantToDelete] = useState(false);
  const[selectedPlans, setSelectedPlans] = useState([]);
  const [plans, setPlans] = useState([]);
  const { id } = useParams();


  useEffect(() =>{
    const fetchData = async () => {
      try {
        const responseGetPlansInfo = await axios.get(`https://app.api.scae.co/api/management/getPlansInfo/${id}`);
        setPlans(responseGetPlansInfo.data)

      } catch (error) {

      }
    };

    fetchData();

  },[id, selectedPlans])

  const handelTakeOffClick = () =>{
    const url = '/manage/project/'+id+'/takeoff/canvas/page/1'
    window.open(url, '_blank'); 
  }

  const updateAttached = async () =>{
    for(const planId of selectedPlans){
      await axios.post(`https://app.api.scae.co/api/management/updateAttached/${planId}/TakeOff`);
      setSelectedPlans([])
    }
  }
  const updateDisAttached = async () =>{
    for(const planId of selectedPlans){
      await axios.post(`https://app.api.scae.co/api/management/updateAttached/${planId}/null`);
      setSelectedPlans([])
    }

  }
  const deletePlan = () =>{
    setWantToDelete(true)
  }
  const cancelDeletePlan = () =>{
    setWantToDelete(false)
  }
  const conformDeletePlan = async () =>{
    for(const planId of selectedPlans){
      await axios.post(`https://app.api.scae.co/api/management/delatePlan/${planId}`);
    }
   setWantToDelete(false)
   setSelectedPlans([])
  }


  const toggleSelectPlan = (id) => {
    setWantToDelete(false)
    setSelectedPlans((prevSelectedPlans) => {
      if (prevSelectedPlans.includes(id)) {
        return prevSelectedPlans.filter((planId) => planId !== id);
      } else {
        return [...prevSelectedPlans, id];
      }
    });
  };

  return (
    <>
    <div id="takeOffContainer">
      <div id="header">
        <div id="left">
          <div>Takeoff</div>
        </div>
        <div id="right">
          <div onClick={handelUploadClick}>Upload Plans</div>
          <div onClick={handelTakeOffClick}>Go to TakeOff</div>
        </div>
      </div>
      <div id="takeOffTable">
          <div id="table">
            <div id='thead'>
              <div>
                <div>Title</div>
                <div>Uploaded</div>
                <div>File Type</div>
                <div>File Size</div>
                <div>Attached To</div>
                <div id="tools">Select</div>
              </div>
            </div>
            <div id="tbody">
            {plans.map((plan, index) => (
                <div key={index} id='row'>
                  <div><span id='rowCountNumber'>{index +1} -</span>{plan.Name}</div>
                  <div>{plan.Uploaded}</div>
                  <div>{plan.Type}</div>
                  <div>{(plan.Size / (1024 * 1024)).toFixed(2)} Mb</div>
                  <div>{plan.AttachedTo !== 'null' && (
                    <>
                    {plan.AttachedTo }
                    </>
                  )}</div>
                  <div id="tools">
                    <div id='select'
                      onClick={() => toggleSelectPlan(plan.ID)}
                      style={{
                        backgroundColor: selectedPlans.includes(plan.ID) ? '#339AF0' : '',
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div id="lowerButton">
            <div>
              {wantToDelete ? (
              <>
              {selectedPlans.length > 0 ? (
                <>Are you sure you want delete selected plan/s?</>
              ):(
                <>Select Plan</>
              )}
              </>
              ) : (
                <>
                </>
              )}

            </div>
            <div>
            {wantToDelete ? (
            <>
            {selectedPlans.length > 0 ?(
              <>
              <div onClick={cancelDeletePlan}>No</div>
              <div id='ConformDelete' onClick={conformDeletePlan}>Yes</div>
              </>
              ):(
                <>
              <div onClick={updateAttached}>Attach</div>
              <div onClick={updateDisAttached}>UnAttached</div>
              <div onClick={deletePlan}>Delete</div>
                </>
              )}
            </>
            ):(
              <>
              <div onClick={updateAttached}>Attach</div>
              <div onClick={updateDisAttached}>UnAttached</div>
              <div onClick={deletePlan}>Delete</div>
              </>
            )}

            </div>
          </div>
        </div>
    </div>

    </>
  );
}


export default TakeOff;
