
  
 export function  formatNumberWithCommas (number){
    // Check if the input is a string and contains commas
    if (typeof number === 'string' && number.includes(',')) {
        // Remove commas from the string
        number = number.replace(/,/g, '');
    }

    // Convert the input to a number if it's a string
    if (typeof number === 'string') {
        // Try to parse the string as a number
        number = parseFloat(number);
    }

    // Check if the number is not null and is a valid number
    if (!isNaN(number)) {
        // Convert the number to a string with no decimal places
        var formattedNumber = number.toFixed(0);

        // Add commas for thousands separators
        formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return formattedNumber;
    } else {
        // Return "0" if the input is not a valid number
        return "0";
    }
}


export function formatDateFromMySQL (mysqlDateString){
// Split the MySQL date string into year, month, and day components
    var parts = mysqlDateString.split('-');
    var year = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var day = parseInt(parts[2], 10);

    // Create a new Date object using the extracted components
    var date = new Date(year, month - 1, day); // Months are zero-based, so subtract 1 from the month

    // Format the date as 'mm/dd/yy' using formatDate function
    return formatDate(date);
}

export function formatDate (date) {
  // Extract month, day, and year components from the Date object
  var month = date.getMonth() + 1; // Months are zero-based, so add 1
  var day = date.getDate();
  var year = date.getFullYear() % 100; // Get last two digits of the year

  // Pad single-digit month and day with leading zeros if necessary
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  // Concatenate components with separators and return the formatted date
  return month + '/' + day + '/' + year;
}