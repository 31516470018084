import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router'
import '../assetsManagement/upload.css';
import IoCloudUpload from "../imgs/icloudupload.png";
import CloudCross from "../imgs/cloud-cross.png";
import CloudDone from "../imgs/cloud-done.png";
import Cloud from "../imgs/cloud.png";
import Close from "../imgs/close.png";
import axios from 'axios';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import ProgressBar from 'react-bootstrap/ProgressBar';


/* import { MdFileUploadOff } from "react-icons/md"; */


pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;






const UploadPlans = ({handelUploadClick}) => {
  const [planFiles, setPlanFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [someToUpload, setSomeToUpload] = useState(false);
  const [progress, setProgress] = useState({progress: 1, for: 'Preparing The Images..', numImg: 0, totalImg: 0});
  const [projectId, setProjectId] = useState('');
  const { id } = useParams();


  useEffect(() => {
    setProjectId(id)

    if (planFiles.every(plan => plan.uploaded === 'true')) {
      setSomeToUpload(false);
    }

  }, [id, planFiles])



  const onDrop = acceptedFiles => {
    for(const plan of acceptedFiles){
      plan['uploaded'] = 'not upload'

    }
    setPlanFiles([...planFiles,...acceptedFiles]);
    setSomeToUpload(true)
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
  
    return new File([u8arr], filename, { type: mime });
  };

  const convertPdfToImages = async (file, progressCallback) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        try {
          const pdfData = new Uint8Array(e.target.result);
          const loadingTask = pdfjsLib.getDocument({ data: pdfData });
          const pdf = await loadingTask.promise;
          const imageList = [];
          const imageFiles = [];

          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);
            const viewport = page.getViewport({ scale: 2});
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

          const pageWidthInches = viewport.width / 144;
          const pageHeightInches = viewport.height / 144;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            await page.render(renderContext).promise;
            const imgSrc = canvas.toDataURL('image/png');
            imageList.push(imgSrc);
            const imageFile = dataURLtoFile(imgSrc, `${pageNumber}`);
            imageFiles.push({
              file: imageFile,
              widthInches: pageWidthInches.toFixed(2), // format to 2 decimal places
              heightInches: pageHeightInches.toFixed(2) // format to 2 decimal places
            });
            if (progressCallback) {
              const progress = ((pageNumber / pdf.numPages)/2) * 100
              progressCallback(progress, pageNumber, pdf.numPages) ;
            }

          }
          resolve({ imageFiles });
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadFiles = async () => {
    if (planFiles.length === 0) {
      alert('Please select at least one PDF file to upload.');
      return;
    }

    for (const plan of planFiles) {
      setProgress({progress: 1, for: 'Preparing The pages..', numImg: 0, totalImg: 0})
      if(plan.uploaded !== 'true' ){
        setUploading(true);


        const progressCallback = (progress, pageNumber, numPages) => {
          setProgress({
            progress: progress.toFixed(0),
            for: 'Preparing The pages..',
            numImg: pageNumber, 
            totalImg: numPages
          });
        };

      // Convert PDF pages to images
        const {imageFiles} = await convertPdfToImages(plan, progressCallback);

        // Upload PDF file
        const pdfFormData = new FormData();
        pdfFormData.append('pdf', plan);
        pdfFormData.append('projectId', projectId);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date().toLocaleDateString('en-US', options);
        pdfFormData.append('uploaded', date);
        try{
          const pdfUploadResponse = await axios.post('https://app.api.scae.co/uploadPlan', pdfFormData, {

            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              setProgress({
                progress: (Math.round((loaded * 25) / total)) + 50,
                for: 'Uploading..',
                numImg: 0, 
                totalImg: 0
              });
            }
          });
          if(pdfUploadResponse.status === 200 ){
            const planId = pdfUploadResponse.data;
            const imageFormData = new FormData();
            imageFormData.append('planId', planId);
            imageFormData.append('projectId', projectId);
            imageFiles.forEach(({ file, widthInches, heightInches }) => {
              imageFormData.append('images', file);
              imageFormData.append(`widthInch`, widthInches);
              imageFormData.append(`heightInch`, heightInches);
              console.log(file)
            });
            

            console.log(imageFormData)

            const uploadImgRes = await axios.post('https://app.api.scae.co/uploadPlanImg', imageFormData, {
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                setProgress({
                  progress: (Math.round((loaded * 25) / total)) + 75,
                  for: 'Uploading..',
                  numImg: 0, 
                  totalImg: 0
                });
              }
            });
            if(uploadImgRes.status === 200){
              plan.uploaded = 'true'
            }else{
              plan.uploaded = 'upload false'
            }
          }else{
            plan.uploaded = 'upload false';
          }
        }catch{
          plan.uploaded = 'upload false';
          

        }

      }

    }
    setUploading(false);
    if (planFiles.every(plan => plan.uploaded === 'true')) {
      setSomeToUpload(false);
    }else{
      setSomeToUpload(true);
    }

  };



  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.pdf' });

  return (
    <div id='uploadMainDiv'>
      <div className="file-upload-container">
        <div id="top">
          <div id="topLeft">
            <div> Upload plans to your Takeoff </div>
            <div> Upload project plans or use existing project files - we only accept PDF format</div>
          </div>
          <div id="topRight">
            <img src={Close} alt="Close" onClick={handelUploadClick} />
          </div>
        </div>
        <div className="upload-area" {...getRootProps()}>
          <input {...getInputProps()} />
          <img src={IoCloudUpload} alt="IoCloudUpload"/>
          <div>Upload files or drag and drop here</div>
        </div>
        <div id="file-list">
          <div id='table_header'>
            Files & Photos
          </div>
          <div id="table">
            <div id='thead'>
              <div>
                <div>Title</div>
                <div>Uploaded</div>
                <div>File Type</div>
                <div>File Size</div>
                <div>Attached To</div>
                <div>Status</div>
              </div>
            </div>
            <div id="tbody">
            {planFiles.map((plan, index) => (
                <div key={index} style={{color: plan.uploaded === 'true' ? '#2222222f' : 'black'}}>
                  <div>{index +1} -</div>
                  <div>{plan.name}</div>
                  <div>{new Date().toLocaleDateString()}</div>
                  <div>pdf</div>
                  <div>{(plan.size / (1024 * 1024)).toFixed(2)} Mb</div>
                  <div>Takeoff</div>
                  <div>
                    { plan.uploaded === 'not upload' ? (
                      <img src={Cloud} alt="Cloud" />
                    ): plan.uploaded === 'true' ? (
                      <img src={CloudDone} alt="CloudDone" />
                    ): plan.uploaded === 'upload false' &&(
                      <img src={CloudCross} alt="CloudDone" />
                    )}  
                    
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {uploading && (
          <div id='uploading'>
            {/* <OrbitProgress variant="dotted" color="#32cd32" size="small" text=" " textColor="" /> */}
            <div>
            <ProgressBar now={progress.progress} label={`${progress.progress}%`} />
            </div>
            <div>{progress.for}
              {progress.for ==='Preparing The pages..' &&(
                <span style={{marginLeft: '5px'}}> {progress.numImg} / {progress.totalImg}</span>
              )}

            </div>
          </div>

        )}

        <div className="actions">
          <button onClick={handelUploadClick} >Cancel</button>
          {someToUpload && (
          <button onClick={uploadFiles} >Create & Continue </button>
          )}
        </div>
        
      </div>
    </div>

  );
};

export default UploadPlans;
