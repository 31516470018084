import React, {Component} from "react";
import addIcon from '../imgs/icons8-add-50.png';
import '../assets/Nav.css';
import axios from 'axios';
import $ from 'jquery'; // Import jQuery



class Nav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tablesName: [],
      TableClicked: ''
    };
    this.handleAddProject = this.handleAddProject.bind(this);
    this.handleAddCompany = this.handleAddCompany.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.navTableClick = this.navTableClick.bind(this);
  }

  handleAddProject() {
    $('#Projects-main-page > #add_forms').show();
    this.props.addFormClick('AddProject');
  }

  handleAddCompany() {
    $('#Projects-main-page > #add_forms').show();
    this.props.addFormClick('AddCompany');
  }

  handleLogOut() {
    console.log('Log Out clicked');
  }

  navTableClick(Table_ID, Table_Name){
    if(this.state.TableClicked !== Table_Name){
      if(Table_ID === 'add_table'){

      }
      else{
        $('#Projects-main-page > div').hide();
        $('nav #bottom_side > div > div:not(:last-child)').css('background-color', '#F1F2F4');
        $('nav #bottom_side > div > div:not(:last-child)').css('color', 'black');
        $('#' + Table_ID).css('background-color', '#fff');
        $('#' + Table_ID).css('color', '#2B73E8');
  
        if(Table_ID === 'Home-button'){
          $('#Projects-main-page > #body').css('display', 'flex');
          this.props.onTableSelect({Table_Name});
        }
        else{
          $('#Projects-main-page > #tables').css('display', 'flex');
          this.props.onTableSelect({Table_Name});
        }
      }
      this.setState(
        {
          TableClicked: Table_Name
        }
      )

    }


  }
  componentDidMount() {
    const fetchTables = async () => {
      try {
        const response = await axios.get('https://app.api.scae.co/api/get_tables_name');
        this.setState({ tablesName: response.data });
      } catch (error) {
        console.error('Error fetching tables:', error);
      }
    };

    fetchTables();
  }


  render(){
     return(
      <nav>
        <div id="top_side">
            <div id="left_side">
                <div>SCAE Projects Management</div>
            </div>
            <div id="right_side">
                <div onClick={this.handleAddProject}>Add Project</div>
                <div onClick={this.handleAddCompany} >Add Company</div>
                <div><a href="https://drive.google.com/drive/u/0/folders/1uh3vmTsXscxidXN3P8TPnznvKlGVUAvl" rel="noopener">Plans</a></div>
                <div><a href="https://drive.google.com/drive/u/0/folders/1krqFP6lM95vqrFb-cr-dWXXZ6ndHn8kM" rel="noopener">Files</a></div>
                <div><a href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=00000002-0000-0ff1-ce00-000000000000&redirect_uri=https%3a%2f%2foutlook.office.com%2fowa%2f&resource=00000002-0000-0ff1-ce00-000000000000&response_mode=form_post&response_type=code+id_token&scope=openid&nonce=638492653070919419.56d5bd0f-23a0-4db1-9ad3-9a0509086365&msaredir=1&client-request-id=096b0f09-0cbe-b486-4f68-3ca9bf9978b5&protectedtoken=true&claims=%7b%22id_token%22%3a%7b%22xms_cc%22%3a%7b%22values%22%3a%5b%22CP1%22%5d%7d%7d%7d&prompt=select_account&state=DYu9DoIwGABB34UN-aC08A3EwcQ4qDGgAVlI_0w0IqRUDG9vh7vpzvc8b-1YOXxw8jJG8hQTRglkgDGmMW4oU1QoeIQJ4RCmSsQhckWcgAJCzgijvnvnaPjxaDuaoR9tMem3lrbjUg7fjw24tNXPFtZ8dTBZbnURB0arp3HRdSj4oQR5OLHjgrNqykkkaI499m3_frUVfYkEZlHvR7HLu3t9hhtRcGnKRdW3Pw" rel="noopener">Email</a></div>
                
                <div onClick={this.handleLogOut} >Log Out</div>
            </div>
        </div>
        <div id="bottom_side">
            <div>
                <div 
                id="Home-button"
                onClick={()=> this.navTableClick('Home-button', 'Home')}
                >Home</div>

                {this.state.tablesName.map((table)=>
                  <div 
                  key ={table.Table_ID} 
                  id={table.Table_ID}
                  onClick={()=> this.navTableClick(table.Table_ID, table.Table_Name)}> 
                  {table.Table_Name}
                  </div>
                )}
                <div id='add_table'> 
                    <div><img src={addIcon} alt="Add Icon"/>
                        Add Table
                    </div>
                </div>
            </div>
        </div>
      </nav>
     );
  }
}


export default Nav;