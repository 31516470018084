import React, {useState, useEffect, useRef}from 'react';
import close from '../../assetsTakeOff/CanvasTools/close.png';
import chevronDown from '../../assetsTakeOff/CanvasTools/chevronDown.png';



const CreateNewGroup = (
  {
    csiDivisions, 
    yourDivisions,
    handelSetCreateNewGroupClose,
    CreateNewGroupRef,
    handelSelectedGroup
  }) =>{

  const [clickOnGroupNameInput, setClickOnGroupNameInput] = useState(false)


  const [clickOnCategoryInput, setClickOnCategoryInput] = useState(false)
  const [displayCategoryMenu, setDisplayCategoryMenu] = useState(false)
  const [clickOnCategoryCSIInput, setClickOnCategoryCSIInput] = useState(false)
  const [displayCategoryCSIMenu, setDisplayCategoryCSIMenu] = useState(false)
  const [filteredDivisionsCSI, setFilteredDivisionsCSI] = useState([])
  const [filteredDivisionsYour, setFilteredDivisionsYour] = useState([])

  const [groupInputsValue, setGroupInputsValue] = useState({group: '', category: '', categoryType: ''});


  const GroupNameInput = useRef(null)
  const CategoryInput = useRef(null)
  const CategoryCSIInput = useRef(null)



  useEffect(() => {
    setFilteredDivisionsYour(yourDivisions.filter(division =>
      division.Name.toLowerCase().includes(groupInputsValue.category.toLowerCase())
    ))

    setFilteredDivisionsCSI(csiDivisions.filter(division =>
      division.Name.toLowerCase().includes(groupInputsValue.category.toLowerCase())
    ))

  },[groupInputsValue,yourDivisions,csiDivisions])

  useEffect(() => {

    const handleClickOutside = (e) => {

      if(GroupNameInput.current && !GroupNameInput.current.contains(e.target)){
        if(groupInputsValue.group ===''){
          setClickOnGroupNameInput(false)
        }
      }
      if(CategoryInput.current && !CategoryInput.current.contains(e.target)){

        if(groupInputsValue.category !== '' && groupInputsValue.categoryType === 'newYourDiv'){

          setDisplayCategoryMenu(false);
        }
        else{
          setClickOnCategoryInput(false);
          
        }
      }
      if(CategoryCSIInput.current && !CategoryCSIInput.current.contains(e.target)){

        if(groupInputsValue.category !== '' && groupInputsValue.categoryType === 'newCSIDiv'){
          setDisplayCategoryCSIMenu(false);
        }
        else{
          setClickOnCategoryCSIInput(false);
        }
      }
    };


    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  // eslint-disable-next-line no-use-before-define
  }, [CategoryInput,GroupNameInput, groupInputsValue,clickOnCategoryInput,CategoryCSIInput]);


  const handelSetClickOnGroupNameInput = () =>{
    setClickOnGroupNameInput(true)
  }
  const handelSetGroupInputValue = (e) =>{
    setGroupInputsValue({
      ...groupInputsValue,
      group: e.target.value
    });
  }
  const handelSetClickOnCategoryInput = () =>{
    setClickOnCategoryInput(true)
    setDisplayCategoryMenu(true)
    setGroupInputsValue({
      ...groupInputsValue,
      category: '',
      categoryType: ''
    });
  }
  const handelSetClickOnCategoryCSIInput = () =>{
    setClickOnCategoryCSIInput(true)
    setDisplayCategoryCSIMenu(true)
    setGroupInputsValue({
      ...groupInputsValue,
      category: '',
      categoryType: ''
    });
  }

  const handelSetCategoryInputValue = (e, type) =>{
    setGroupInputsValue({
      ...groupInputsValue,
      category: e.target.value,
      categoryType: type
    });
    if(type === 'newCSIDiv'){
      setDisplayCategoryCSIMenu(true)
    }
    else{
      setDisplayCategoryMenu(true)
    }
  }
  const handelCategoryInputValue = (divisionName,type) =>{
    setGroupInputsValue({
      ...groupInputsValue,
      category: divisionName,
      categoryType: type
    });
    if(type === 'newCSIDiv'){
      setDisplayCategoryCSIMenu(false)
    }
    else{
      setDisplayCategoryMenu(false)
    }
    
  }
  const handelClickOninputCategoryArrow = (type) =>{
    if(type === 'newCSIDiv'){
    setClickOnCategoryCSIInput(true)
      setFilteredDivisionsCSI(csiDivisions)
      setDisplayCategoryCSIMenu(true)
    }else{
      setClickOnCategoryInput(true)
      setDisplayCategoryMenu(true)
      setFilteredDivisionsYour(yourDivisions)
    }


  }

  const handelOnSubmit = () =>{
    handelSelectedGroup(groupInputsValue)
    handelSetCreateNewGroupClose()
  }

  return (
    <div id="createNewGroup" ref={CreateNewGroupRef}>
      <div>
        <div id="header">
          <div id="headerText">
            Create New Group
          </div>
          <img src={close} alt="" onClick={handelSetCreateNewGroupClose} className='itIN'/>
        </div>

        <div id="bodyContent">
          <div id="inputGroup" ref={GroupNameInput}>
            {clickOnGroupNameInput ? (
              <div id='grpNameActive'>
                <div>Group Name</div>
                <input type="text"
                value={groupInputsValue.group}
                onChange={(e) => handelSetGroupInputValue(e)}
                autoFocus />
              </div>
              
            ):(
              <div id="GroupNameText" 
              onClick={handelSetClickOnGroupNameInput}>Group Name</div>
            )}
          </div>
          <div id="inputCategory" ref={CategoryInput}>
            {clickOnCategoryInput ? (
              <div>
              <div id='CategoryActive'>
                <div>Category</div>
                <input type="text"
                  value={groupInputsValue.categoryType === 'newYourDiv' ? groupInputsValue.category : ''}
                  onChange={(e) => handelSetCategoryInputValue(e, 'newYourDiv')}
                  autoFocus />
              </div>
              
              {displayCategoryMenu && (
                <div id="menu">
                  {filteredDivisionsYour.map((division, index) => (
                    <div key={index} className='itIN'>
                      <div onClick={() => handelCategoryInputValue(division.Name, 'newYourDiv')} className='itIN'>
                        {division.Name}
                      </div>
                    </div>
                  ))}
                </div>
              )}

            </div>
            ):(
              <div id="CategoryText" 
              onClick={handelSetClickOnCategoryInput}>
                Category
              </div>
            )}
            <img id='inputCategoryImg' 
              src={chevronDown} 
              alt="chevronDown" 
              onClick={() => handelClickOninputCategoryArrow('newYourDiv')}/>
          </div>


          
          <div id="inputCategory" ref={CategoryCSIInput}>
            {clickOnCategoryCSIInput ? (
              <div>
              <div id='CategoryActive'>
                <div>CSI Division</div>
                <input type="text"
                  value={groupInputsValue.categoryType === 'newCSIDiv' ? groupInputsValue.category : ''}
                  onChange={(e) => handelSetCategoryInputValue(e, 'newCSIDiv')}
                  autoFocus 
                  placeholder='Search'/>
              </div>
              
              {displayCategoryCSIMenu && (
                <div id="menu">
                  {filteredDivisionsCSI.map((division, index) => (
                    <div key={index} className='itIN'>
                      <div onClick={() => handelCategoryInputValue(division.Name, 'newCSIDiv')} className='itIN'>
                        {division.Name}
                      </div>
                    </div>
                  ))}
                </div>
              )}

            </div>
            ):(
              <div id="CategoryText" 
              onClick={handelSetClickOnCategoryCSIInput}>
                CSI Division
              </div>
            )}
            <img id='inputCategoryImg' 
              src={chevronDown} 
              alt="chevronDown" 
              onClick={() => handelClickOninputCategoryArrow('newCSIDiv')}/>
          </div>
        </div>
        <div id="bottom">
          <div id="button">
            <div id="cancel" onClick={handelSetCreateNewGroupClose} className='itIN'>Cancel</div>
            <div id="update" onClick={handelOnSubmit}>Update</div>
          </div>
        </div>
        
      </div>
    </div>
  )
}







export default CreateNewGroup;