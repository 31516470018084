import axios from 'axios';
import {useState } from 'react';


export const Rectangle = (
  zoom,
  lineSize,
  selectedColor, 
  selectedGroup,
  ppi,
  rectangles,
  setRectangles,
  projectId, 
  pageID
) => {

  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [currentRectangle, setCurrentRectangle] = useState(null);
  
  const handleMouseDownRectangle = (e) => {
    const { offsetX, offsetY } = e.evt;
    setStartPoint({ x: offsetX / zoom, y: offsetY / zoom });
    let recNumber
    if(rectangles){
      recNumber = rectangles.length + 1
    }else{
      recNumber = 1
    }
    setCurrentRectangle({
      id: Math.floor(100000000000 + Math.random() * 900000000000),
      name: 'rec '+recNumber,
      category: selectedGroup.category,
      group: selectedGroup.group,
      type: 'rec',
      points:{
        x: offsetX / zoom,
        y: offsetY / zoom,
        width: 0,
        height: 0
      },
      lineWidth: lineSize /2,
      color: selectedColor,
      measure: 0,
      updatedLength: 1,
      projectId: projectId, 
      pageID: pageID
    });
    setIsDrawing(true);
  };

  const handleMouseUpRectangle = (e) => {
    if(currentRectangle && currentRectangle.measure > 0.05){
      if(rectangles){
        setRectangles([...rectangles, currentRectangle]);
      }
      else{
        setRectangles([currentRectangle]);
      }
      const data = currentRectangle
      axios.post(`https://app.api.scae.co/api/measurements/addMeasure`,{data})

      setCurrentRectangle(null)

    }
    setIsDrawing(false);
  };

  const handleMouseMoveRectangle = (e) => {
    if (!isDrawing) return;

    const { offsetX, offsetY } = e.evt;
    const x = startPoint.x;
    const y = startPoint.y;
    const width = (offsetX / zoom) - x;
    const height = (offsetY / zoom) - y;
    const widthInInch = width / ppi;
    const heightInInch = height / ppi;
    let area = (widthInInch * heightInInch) * 4

    if(area < 0){
      area = area * -1
    }

    setCurrentRectangle({
      ...currentRectangle,
      points:{
        x: startPoint.x,
        y: startPoint.y,
        width: width,
        height: height,
      },

      measure: area
    });

  };





  return {
    handleMouseMoveRectangle,
    handleMouseUpRectangle,
    handleMouseDownRectangle,
    currentRectangle
  };
}

