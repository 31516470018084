import React, {useState, useEffect}from 'react';
import { useParams, useNavigate } from 'react-router';
import Nav from './Nav';
import axios from 'axios';
import '../assetsTakeOff/AllPages.css';
import Canvas from './Canvas';
import Test from './test';
import ProjectFiles from './ProjectFiles';
import Measurements from './Measurements';
import Cookies from 'js-cookie';
import { ThreeDot } from 'react-loading-indicators';





const Manage = () => {
  const [projectInfo , setProjectInfo] = useState({});
  const [companyInfo , setCompanyInfo] = useState([]);
  const navigate = useNavigate();
  const [zoom, setZoom] = useState(1);
  const [drawMode, setDrawMode] = useState('move'); // 'line' or 'rectangle'
  const [lineSize, setLineSize] = useState(1)
  const [scaleRatio, setScale] = useState(48)
  const [selectedColor, setSelectedColor] = useState(() => {
    const savedColor = Cookies.get('savedColor');
    return savedColor ? savedColor : '#000000';
  });

 // Canvas
  const [pageInfo, setPageInfo] = useState(null);
  const [pageId, setPageId] = useState('')
  const [image, setImage] = useState(null);

  // 'Group'
  const [csiDivisions, setCsiDivisions] = useState([])
  const [yourDivisions, setYourDivisions] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({group:'Individual', category:'', categoryType:''});

  // Projects Files
  const[plan, setPlan]= useState([]);
  const[folders, setFolders]= useState([]);
  const[PlanPages, setPlanPages]= useState([]);


  // canvas
  const [AllLines, setAllLines] = useState(null);
  const [rectangles, setRectangles] = useState(null);
  const [polygons, setPolygons] = useState(null);
  const [AllCounts, setAllCounts] = useState(null);

  const [selectMeasureDrawing , setSelectMeasureDrawing] = useState([])




  const { projectId, pageName } = useParams();

  useEffect(() =>{

    const fetchData = async () => {
      try {
        const [
          responseProjectInfo,
          resultCategories,
          resultGroups,
          plansContents
        ] = await Promise.all([
          axios.get(`https://app.api.scae.co/api/management/projectInfo/${projectId}`),
          axios.get(`https://app.api.scae.co/api/management/getCategories`),
          axios.get(`https://app.api.scae.co/api/management/getGroups/${projectId}`),
          axios.get(`https://app.api.scae.co/api/projectFiles/getPlansContents/${projectId}`)
        ]);


        setProjectInfo(responseProjectInfo.data.project);
        setCompanyInfo(responseProjectInfo.data.companies);
        setCsiDivisions(resultCategories.data.CSI);
        setYourDivisions(resultCategories.data.Your);
        setGroups(resultGroups.data);
        setPlan(plansContents.data.plans)
        setFolders(plansContents.data.folders)
        setPlanPages(plansContents.data.pages)


      } catch (error) {

      }
    };




    fetchData();

  },[projectId])

  useEffect(() =>{

    const fetchData = async () => {
      setAllLines(null)
      setRectangles(null)
      setPolygons(null)
      setAllCounts(null)
      setPageInfo(null)
      setImage(null)
      try {
        const [
          resultPageInfo
        ] = await Promise.all([
          axios.get(`https://app.api.scae.co/api/getPlanImageCanvas/${projectId}/${pageName}`)
        ]);
        setPageInfo(resultPageInfo.data[0])
        setPageId(resultPageInfo.data[0].id)
        if(resultPageInfo.status === 200){
          
          const resMeasureData = await axios.get(`https://app.api.scae.co/api/measurements/getMeasure/${resultPageInfo.data[0].id}`);

          if(resMeasureData.status === 200){

            if(resMeasureData.data.count.length > 0){
              const measureDataCount = resMeasureData.data.count.map(item => {
                return {
                  ...item,
                  points: JSON.parse(item.points)  // Convert the JSON string to a JavaScript object/array
                };
              });
              setAllCounts(measureDataCount)
            }
            if(resMeasureData.data.len.length > 0){
              const measureDataLen = resMeasureData.data.len.map(item => {
                return {
                  ...item,
                  points: JSON.parse(item.points)  // Convert the JSON string to a JavaScript object/array
                };
              });
              setAllLines(measureDataLen)
            }
            if(resMeasureData.data.rec.length > 0){
              const measureDataRec = resMeasureData.data.rec.map(item => {
                return {
                  ...item,
                  points: JSON.parse(item.points)  // Convert the JSON string to a JavaScript object/array
                };
              });
              setRectangles(measureDataRec)
            }
            if(resMeasureData.data.poly.length > 0){
              const measureDataPoly = resMeasureData.data.poly.map(item => {
                return {
                  ...item,
                  points: JSON.parse(item.points)  // Convert the JSON string to a JavaScript object/array
                };
              });
              setPolygons(measureDataPoly)
            }
          }



        }


      } catch (error) {

      }
    };

    const getMainImgName = async () =>{
      const response = await axios.get(`https://app.api.scae.co/api/getPlanImageCanvas/${projectId}/getMainPageName`);
      if (response.status === 200) {
        const pageName = response.data[0].name
        navigate(`/manage/project/${projectId}/takeoff/canvas/page/${pageName}`); 
      }
    
    }


    if(pageName === undefined){
      getMainImgName()
    }else{
      fetchData();
    }

  },[projectId, pageName, navigate])





  useEffect(() => {
    // Avoid adding to the groups repeatedly by using a flag or a similar mechanism
    let updatedYourDivisions = yourDivisions.map(division => ({
      ...division,
      Groups: [...division.Groups] // Copy existing groups to avoid mutation
    }));
    
    const updatedCsiDivisions = csiDivisions.map(division => ({
      ...division,
      Groups: [...division.Groups] // Copy existing groups to avoid mutation
    }));
  
    groups.forEach(group => {
      const matchingYouDivision = updatedYourDivisions.find(div => div.Name === group.Category);
      const matchingCSIDivision = updatedCsiDivisions.find(div => div.Name === group.Category);
  
      if (matchingYouDivision && !matchingYouDivision.Groups.includes(group.Name)) {
        matchingYouDivision.Groups.push(group.Name);  
      }
      if (matchingCSIDivision && !matchingCSIDivision.Groups.includes(group.Name)) {
        matchingCSIDivision.Groups.push(group.Name);
      }
      if(!matchingCSIDivision && !matchingYouDivision){

        const findIndividual = updatedYourDivisions.find(div => div.Name === 'Individual');
        if(findIndividual){
          if (!findIndividual.Groups.includes(group.Name)) {
            findIndividual.Groups = [...findIndividual.Groups, group.Name];
          }
        }else{
          updatedYourDivisions = [
            ...updatedYourDivisions,
            {
              Name: 'Individual',
              Groups: [group.Name] // Groups should be an array
            }
          ];
        }
      }
    });
  
    // Sort the divisions by name
    updatedYourDivisions.sort((a, b) => a.Name.localeCompare(b.Name));
    updatedCsiDivisions.sort((a, b) => a.Name.localeCompare(b.Name));
  
    // Update the state only once after processing all groups
    setYourDivisions(updatedYourDivisions);
    setCsiDivisions(updatedCsiDivisions);
  
  }, [groups]);


  const handleZoomIn = () => {
    const canvasDiv = document.querySelector('#canvas');
    const computedStyleWidth = window.getComputedStyle(canvasDiv).width;
    const computedStyleHeight = window.getComputedStyle(canvasDiv).height;
    const width = parseFloat(computedStyleWidth);
    const height = parseFloat(computedStyleHeight);
  if(zoom < 3.59){
    setZoom((prevZoom) => prevZoom * 1.2);

    setDrawMode('move')
    const newWidth = width * 1.1;
    const newHeight = height * 1.1;
    canvasDiv.style.width = `${newWidth}px`;
    canvasDiv.style.height = `${newHeight}px`;
    

  }
  };

  const handleZoomOut = (e) => {
    //setDrawMode('rectangle')

    const canvasDiv = document.querySelector('#canvas');
    const computedStyleWidth = window.getComputedStyle(canvasDiv).width;
    const computedStyleHeight = window.getComputedStyle(canvasDiv).height;
    const width = parseFloat(computedStyleWidth);
    const height = parseFloat(computedStyleHeight);
    if(zoom > .99){
      setZoom((prevZoom) => prevZoom / 1.2);
      setDrawMode('move')
      const newWidth = width / 1.1;
      const newHeight = height / 1.1;
      canvasDiv.style.width = `${newWidth}px`;
      canvasDiv.style.height = `${newHeight}px`;
    }

  };

  const handleDrawMode = (type) => {
    setDrawMode(type)
  }

  const handelSetSelectedColor = (color) =>{
    setSelectedColor(color.hex);
    Cookies.set('savedColor', color.hex);
  }
  const handelSetLineSize = (size) =>{
    setLineSize(size);
  }
  const handelSetScaleSize = (ratio) => {
    setScale(ratio)
  }

  const handelSelectedGroup = (SelectedGroupData) => {
    setSelectedGroup(SelectedGroupData)
    const data ={
      Project_ID: projectId,
      Name: SelectedGroupData.group,
      Category: SelectedGroupData.category
     }
    if(SelectedGroupData.categoryType === 'newYourDiv'){

      const division = yourDivisions.find(div => div.Name === SelectedGroupData.category);
      if (division) {
        division.Groups.push(SelectedGroupData.group);        
        axios.post(`https://app.api.scae.co/api/management/addGroups`,data);
      } else {
        setYourDivisions(prevDiv => 
          [...prevDiv,
            {
              Name: SelectedGroupData.category,
              Groups:[SelectedGroupData.group]
            }
           ])
           axios.post(`https://app.api.scae.co/api/management/addCategories/${SelectedGroupData.category}`);
           axios.post(`https://app.api.scae.co/api/management/addGroups`,data);
      }
    }else if(SelectedGroupData.categoryType === 'newCSIDiv'){
      const division = csiDivisions.find(div => div.Name === SelectedGroupData.category);
      if (division) {
        division.Groups.push(SelectedGroupData.group);        
        axios.post(`https://app.api.scae.co/api/management/addGroups`,data);
      } else {
        setYourDivisions(prevDiv => 
          [...prevDiv,
            {
              Name: SelectedGroupData.category, 
              Groups:[SelectedGroupData.group]
            }
           ])
           axios.post(`https://app.api.scae.co/api/management/addCategories/${SelectedGroupData.category}`);
           axios.post(`https://app.api.scae.co/api/management/addGroups`,data);
      }
    }
  }


  const handelScrollContent = () => {
    const scrollContent = document.querySelector('#canvasSection');
    const canvas = document.querySelector('#canvas');
  
    
    // Center the scrollbar by setting scrollLeft to the middle
    scrollContent.scrollLeft = canvas.scrollWidth * 0.20;
    
    // Set scrollTop to 25% of the canvas height
    scrollContent.scrollTop = canvas.scrollHeight * 0.23;

  };





  return (
    plan ? 
    (
      <div id="mainSection">
        <Nav 
          handleZoomIn={handleZoomIn} 
          handleZoomOut={handleZoomOut} 
          handleDrawMode={handleDrawMode} 
          drawMode={drawMode}
          handelSetSelectedColor={handelSetSelectedColor}
          handelSetLineSize={handelSetLineSize}
          lineSize={lineSize}
          selectedColor={selectedColor}
          handelSetScaleSize={handelSetScaleSize}
          selectedGroup={selectedGroup}
          handelSelectedGroup={handelSelectedGroup}
          csiDivisions={csiDivisions}
          yourDivisions={yourDivisions}
          setYourDivisions={setYourDivisions}
          setCsiDivisions={setCsiDivisions}
        />
        <Canvas 
          zoom={zoom} 
          drawMode={drawMode}
          lineSize={lineSize}
          scaleRatio={scaleRatio}
          selectedColor={selectedColor}
          selectedGroup={selectedGroup}
          AllLines={AllLines}
          setAllLines={setAllLines}
          rectangles={rectangles}
          setRectangles={setRectangles}
          polygons={polygons}
          setPolygons={setPolygons}
          AllCounts={AllCounts}
          setAllCounts={setAllCounts}
          selectMeasureDrawing={selectMeasureDrawing}
          setSelectMeasureDrawing={setSelectMeasureDrawing}
          pageInfo={pageInfo}
          pageId={pageId}
          image={image}
          setImage={setImage}
          />
        <ProjectFiles 
        plans={plan}
        setPlans={setPlan}
        folders={folders}
        setFolders={setFolders}
        PlanPages={PlanPages}
        handelScrollContent={handelScrollContent}
       />
       <Measurements 
          AllLines={AllLines}
          scaleRatio={scaleRatio}
          AllRectangles={rectangles}
          AllPolygons={polygons}
          AllCounts={AllCounts}
       />
    </div>

    ) : 
    (
    <div id="mainPageLoading">
    <ThreeDot color="black" size="small" text="" textColor="" />
    </div>)
    
/*     
 */

    
  );
}


export default Manage;
