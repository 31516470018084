import React from 'react';

const Task = () => {
  return (
    <div className="task">
      <button>Create New Task</button>
    </div>
  );
};

export default Task;