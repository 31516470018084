import React, {useState, useEffect, useRef}from 'react';
import { CompactPicker } from 'react-color';
import Cookies from 'js-cookie';
import { useParams, useNavigate } from 'react-router-dom';



import count from '../assetsTakeOff/CanvasTools/count.png';
import redo from '../assetsTakeOff/CanvasTools/redo.png';
import polygon from '../assetsTakeOff/CanvasTools/polygon.png';
import move from '../assetsTakeOff/CanvasTools/hand.png';
import length from '../assetsTakeOff/CanvasTools/length.png';
import rectangle from '../assetsTakeOff/CanvasTools/rectangle.png';
import exportCsv from '../assetsTakeOff/CanvasTools/export-csv.png';
import estimate from '../assetsTakeOff/CanvasTools/estimate.png';
import project from '../assetsTakeOff/CanvasTools/project.png';
import upload from '../assetsTakeOff/CanvasTools/upload.png';
import measurement from '../assetsTakeOff/CanvasTools/measurement.png';
import ruler from '../assetsTakeOff/CanvasTools/ruler.png';
import width from '../assetsTakeOff/CanvasTools/width.png';
import select from '../assetsTakeOff/CanvasTools/object.png';
import undo from '../assetsTakeOff/CanvasTools/undo.png';
import zoomToActualSize from '../assetsTakeOff/CanvasTools/zoom-to-actual-size.png';
import zoomOut from '../assetsTakeOff/CanvasTools/zoomOut.png';
import zoomIn from '../assetsTakeOff/CanvasTools/zoomIn.png';
import warning from '../assetsTakeOff/CanvasTools/warning.png';
import uncheckedCheckbox from '../assetsTakeOff/CanvasTools/uncheckedCheckbox.png';
import checkedCheckbox from '../assetsTakeOff/CanvasTools/checkedCheckbox.png';
import close from '../assetsTakeOff/CanvasTools/close.png';
import exportFile from '../assetsTakeOff/CanvasTools/export.png';

import '../assetsTakeOff/Nav.css';


import CreateNewGroup  from './NavTools/CreateNewGroup'
import Group from './NavTools/Group'




const Nav = (
  {handleZoomIn, 
  handleZoomOut, 
  handleDrawMode,
  drawMode,
  handelSetSelectedColor,
  handelSetLineSize,
  lineSize,
  selectedColor,
  handelSetScaleSize,
  selectedGroup,
  handelSelectedGroup,
  csiDivisions,
  yourDivisions,
  setYourDivisions,
  setCsiDivisions
  }) => {
  const [colorMode, setColorMode] = useState(false)
  const [lineWidthMode, setLineWidthMode] = useState(false)
  const [displayLineSelectMenu, setDisplayLineSelectMenu] = useState(false)
  const [setScaleMode, setSetScaleMode] = useState(false)
  const [displaySetScaleMenu, setDisplaySetScaleMenu] = useState(false)
  const [displayScale, setDisplayScale] = useState(`1/4" = 1'`);
  const [displayWarningDivUpdateScale, SetDisplayWarningDivUpdateScale] = useState(false)
  const [scaleRatio, SetScaleRatio] = useState(null)
  const [scaleDSAChecked, SetScaleDSAChecked] = useState(() => {
    const scaleDSAChecked = Cookies.get('scaleDSAChecked');
    return scaleDSAChecked === 'true'; // Ensure this returns a boolean
  });

  const [createNewGroup, SetCreateNewGroup] = useState(false)

  const { projectId } = useParams();
  const navigate = useNavigate();



  const toolsColorContainerRef = useRef(null);
  const toolsLineContainerRef = useRef(null);
  const toolsSetScaleContainerRef = useRef(null)
  const updateScaleBox = useRef(null)

  const CreateNewGroupRef = useRef(null)


  const asImperial= [
    {DScale: `1/16" = 1'0` ,Ratio: 192},
    {DScale: `3/32" = 1'0` ,Ratio: 128},
    {DScale: `1/8" = 1'0` ,Ratio: 96},
    {DScale: `3/16" = 1'0` ,Ratio: 64},
    {DScale: `1/4" = 1'0` ,Ratio: 48},
    {DScale: `3/8" = 1'0` ,Ratio: 32},
    {DScale: `1/2" = 1'0` ,Ratio: 24},
    {DScale: `3/4" = 1'0` ,Ratio: 16},
    {DScale: `1" = 1'0` ,Ratio: 12},
    {DScale: `1 1/2" = 1'0` ,Ratio: 8},
    {DScale: `3" = 1'0` ,Ratio: 4},
    {DScale: `Full Scale` ,Ratio: 1}
  ]

  const asSI= [
    {DScale: `1:1000` ,Ratio: 1000},
    {DScale: `1:500` ,Ratio: 500},
    {DScale: `1:200` ,Ratio: 200},
    {DScale: `1:100` ,Ratio: 100},
    {DScale: `1:50` ,Ratio: 50},
    {DScale: `1:20` ,Ratio: 20},
    {DScale: `1:10` ,Ratio: 10},
    {DScale: `1:5` ,Ratio: 5},
    {DScale: `1:2` ,Ratio: 2},
    {DScale: `1:1` ,Ratio: 1}
  ]
  const EngImperial= [
    {DScale: `1" = 100'` ,Ratio: 1200},
    {DScale: `1" = 60'` ,Ratio: 720},
    {DScale: `1" = 50'` ,Ratio: 600},
    {DScale: `1" = 40'` ,Ratio: 480},
    {DScale: `1" = 30'` ,Ratio: 360},
    {DScale: `1" = 20'` ,Ratio: 240},    
    {DScale: `1" = 10'` ,Ratio: 120},
  ]






  useEffect(() => {

    const handleClickOutside = (e) => {
      if (!toolsColorContainerRef.current.contains(e.target)) {
        setColorMode(false);
      }
      if (!toolsLineContainerRef.current.contains(e.target)) {
        setLineWidthMode(false);
        setDisplayLineSelectMenu(false);
      }
      if (!toolsSetScaleContainerRef.current.contains(e.target)){

        if  (!updateScaleBox.current){
        setSetScaleMode(false);
        setDisplaySetScaleMenu(false);
        }
      }

    };
    const handleKeyDown = (e) => {
    
      if (e.key === 'Enter') {
        setColorMode(false);
      }

    };

    window.addEventListener('click', handleClickOutside);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleKeyDown);
    };
  // eslint-disable-next-line no-use-before-define
  }, []);



  const handelSetColorMode =() =>{
    setColorMode((prevColorMode) => !prevColorMode)
  }


// line width 
  const handelLineWithMode = () =>{
    setLineWidthMode((prevClick) => !prevClick)
  }

  const handelSelectLineSizeMenu = () =>{
    setDisplayLineSelectMenu((prevClick) => !prevClick)
  }
  const handelSelectLineSize = (size) =>{
    handelSetLineSize(size)
    setDisplayLineSelectMenu(false)
  }


  // SetScale width 
  const handelSetScaleMode = () =>{
    setSetScaleMode((prevClick) => !prevClick)
  }

  const handelSetScaleMenu = () =>{
    setDisplaySetScaleMenu((prevClick) => !prevClick)
    
  }
  const handelScaleSize = (ratio) =>{
    SetScaleRatio(ratio)
    if(scaleDSAChecked){
      handelUpdateScale()
    }
    else{
      SetDisplayWarningDivUpdateScale(true)
    }

  }

  const handelCloseWarningDivUpdateScale = () =>{
      SetDisplayWarningDivUpdateScale(false)
      SetScaleDSAChecked(false)
      Cookies.set('scaleDSAChecked', false)
    }


  const handelUpdateScale = () =>{
    handelSetScaleSize(scaleRatio)
    handelSetDisplayScale(scaleRatio)
    setSetScaleMode(false);
    setDisplaySetScaleMenu(false);
    SetDisplayWarningDivUpdateScale(false)
  }
  const handelSetDisplayScale = (ratio) =>{
    const asImperialSearch = asImperial.find((scale) => scale.Ratio === ratio);
    const asSISearch = asSI.find((scale) => scale.Ratio === ratio);
    const EngImperialSearch = EngImperial.find((scale) => scale.Ratio === ratio);
    if (asImperialSearch) {
      setDisplayScale(asImperialSearch.DScale);
    }
    else if(asSISearch) {
      setDisplayScale(asSISearch.DScale);
    }
    else if(EngImperialSearch) {
      setDisplayScale(EngImperialSearch.DScale);
    }
  }

  const handelDontShowItCheckBoxUpdateScale = () =>{
    SetScaleDSAChecked((prevClick) => {
      const scaleDSAChecked = !prevClick
      Cookies.set('scaleDSAChecked', scaleDSAChecked);
      return scaleDSAChecked;
    })
  }



  // Group 


  const handelSetCreateNewGroupClose = () =>(
    SetCreateNewGroup(false)
  )
  const handelSetCreateNewGroupOpen = () =>(
    SetCreateNewGroup(true)
  )





  const bgNavToolsColor = '#abbade2f'
  return (
    <div id='nav'>

      <div id="toolsSections">
        <div id="toolsSection">
          <div id="Navigate">
            <div id='tool'>
              <div id="ToolsIcon">
                <img src={project} alt="project"/>
              </div>
              <div id="toolsIconName">
                Project
              </div>
            </div>
            <div id='tool'>
              <div id="ToolsIcon">
                <img src={upload} alt="upload"/>
              </div>
              <div id="toolsIconName">
                Upload
              </div>
            </div>
          </div>
          <div id="toolsSectionName">
            Files
          </div>
        </div>

        <div id="toolsSection">
          <div id="Navigate">
            <div id='tool'>
              <div id="ToolsIcon">
                <img src={redo} alt="redo" onClick={() => handleDrawMode('redo')}/>
              </div>
              <div id="toolsIconName">
                Redo
              </div>
            </div>
            <div id='tool'>
              <div id="ToolsIcon">
                <img src={undo} alt="undo" onClick={() => handleDrawMode('undo')}/>
              </div>
              <div id="toolsIconName">
                Undo
              </div>
            </div>
          </div>
          <div id="toolsSectionName">
            Navigate
          </div>
        </div>

        <div id="toolsSection">
          <div id="Zoom">
            <div id='tool'>
              <div id="ToolsIcon">
                <img src={zoomToActualSize} alt="zoomToActualSize" onClick={() => handleDrawMode('zoomToActualSize')}/>
              </div>
              <div id="toolsIconName">
              Act. Size
              </div>
            </div>
            <div id='tool' onClick={handleZoomOut}>
              <div id="ToolsIcon">
                <img src={zoomOut} alt="zoomOut" />
              </div>
              <div id="toolsIconName">
                Zoom Out
              </div>
            </div>
            <div id='tool' onClick={handleZoomIn} >
              <div id="ToolsIcon">
                <img src={zoomIn} alt="zoomIn"/>
              </div>
              <div id="toolsIconName">
                Zoom In
              </div>
            </div>
          </div>
          <div id="toolsSectionName">
            Zoom
          </div>
        </div>

        <div id="toolsSection">
          <div id="Measure">
            <div id='tool' onClick={handleZoomOut} >
              <div id="ToolsIcon">
                <img src={measurement} alt="Dimension" />
              </div>
              <div id="toolsIconName">
                Dimension
              </div>
            </div>
            <div id='tool' 
              ref={toolsSetScaleContainerRef}
              className='toolsSetScaleContainer'
              style={{backgroundColor: setScaleMode ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon" onClick={handelSetScaleMode}>
                <img src={ruler} alt="ruler"/>
              </div>
              <div id="toolsIconName" onClick={handelSetScaleMode}>
                Set Scale
              </div>
              {setScaleMode && (
              <div className="setScaleBox">
                <div class="arrow-up"></div>
                <div id="setScaleBoxContent">
                  <div id="name">Page Scale</div>
                  <div id="setScaleSelector">
                    <div onClick={handelSetScaleMenu}>
                      {displayScale}
                    </div>
                    
                    {displaySetScaleMenu && (

                    <div id="selectMenu">
                      <div id='noHover'>
                        <div >Architect (Imperial)</div>
                      </div>
                      {asImperial.map((unit, i) =>(
                      <div key={i} onClick={() => handelScaleSize(unit.Ratio)}>
                        <div>{unit.DScale}</div>
                      </div>
                      ))}
                      <div id='noHover'>
                        <div>Engineer (Imperial)</div>
                      </div>
                      {EngImperial.map((unit, i) =>(
                      <div key={i} onClick={() => handelScaleSize(unit.Ratio)}>
                        <div>{unit.DScale}</div>
                      </div>
                      ))}
                      <div id='noHover'>
                        <div>Architect (SI)</div>
                      </div>
                      {asSI.map((unit, i) =>(
                      <div key={i} onClick={() => handelScaleSize(unit.Ratio)}>
                        <div>{unit.DScale}</div>
                      </div>
                      ))}

                    </div>
                    )}

                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
          <div id="toolsSectionName">
            Measure
          </div>
        </div>

        <div id="toolsSection">
          <div id="Measure">
            <div id='tool'               
              onClick={() => handleDrawMode('select')} 
              style={{backgroundColor: drawMode==='select' ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon">
                <img src={select} alt="select"/>
              </div>
              <div id="toolsIconName">
                Select
              </div>
            </div>
            <div id='tool'               
              onClick={() => handleDrawMode('move')} 
              style={{backgroundColor: drawMode==='move' ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon">
                <img src={move} alt="move"/>
              </div>
              <div id="toolsIconName">
                Move
              </div>
            </div>
          </div>
          <div id="toolsSectionName">
            Move/Select
          </div>
        </div>

        <div id="toolsSection">
          <div id="TakeOff">
            <div id='tool'               
              onClick={() => handleDrawMode('rectangle')} 
              style={{backgroundColor: drawMode==='rectangle' ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon">
                <img src={rectangle} alt="rectangle"/>
              </div>
              <div id="toolsIconName">
                Rectangle
              </div>
            </div>
            <div id='tool' 
              onClick={() => handleDrawMode('polygon')} 
              style={{backgroundColor: drawMode==='polygon' ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon">
                <img src={polygon} alt="polygon" />
              </div>
              <div id="toolsIconName">
              Polygon
              </div>
            </div>
            <div id='tool'               
              onClick={() => handleDrawMode('line')} 
              style={{backgroundColor: drawMode==='line' ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon">
                <img src={length} alt="length"/>
              </div>
              <div id="toolsIconName">
                Length
              </div>
            </div>
            <div id='tool'               
              onClick={() => handleDrawMode('count')} 
              style={{backgroundColor: drawMode==='count' ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon">
                <img src={count} alt="count"/>  
              </div>
              <div id="toolsIconName">
                Count
              </div>
            </div>

            <div id='tool' 
              className='toolsColorContainer' 
              ref={toolsColorContainerRef}
              style={{backgroundColor: colorMode ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon" onClick={handelSetColorMode}>
                <div id="color" style={{backgroundColor: selectedColor}}></div>
              </div>
              <div id="toolsIconName" onClick={handelSetColorMode}>
                Color
              </div>
              {colorMode && (
              <div className="colorBox">
                <div class="arrow-up"></div>
                <div id="CompactColor">
                  <CompactPicker color={selectedColor} onChange={handelSetSelectedColor}/>
                </div>
              </div>
              )}

            </div>

            <div id='tool' 
              ref={toolsLineContainerRef}
              className='toolsLineContainer'
              style={{backgroundColor: lineWidthMode ? bgNavToolsColor : ''}}>
              <div id="ToolsIcon" onClick={handelLineWithMode}>
                <img src={width} alt="width"/>  
              </div>
              <div id="toolsIconName" onClick={handelLineWithMode}>
                Width
              </div>
              {lineWidthMode && (
              <div className="lineBox">
                <div class="arrow-up"></div>
                <div id="lineBoxContent">
                  <div id="name">Line Width</div>
                  <div id="lineSelector">
                    <div onClick={handelSelectLineSizeMenu}>
                      <img src={width} alt="" />
                      {lineSize}
                    </div>
                    {displayLineSelectMenu && (
                    <div id="selectMenu">
                      <div onClick={() => handelSelectLineSize(0.2)}>
                        <div>
                          <div style={{height: '0.5px'}}></div>
                        </div>
                        <div>0.2</div>
                      </div>
                      <div onClick={() => handelSelectLineSize(0.7)}>
                        <div>
                          <div style={{height: '1.8px'}}></div>
                        </div>
                        <div>0.7</div>
                      </div>
                      <div onClick={() => handelSelectLineSize(1.0)}>
                        <div>
                          <div style={{height: '3px'}}></div>
                        </div>
                        <div>1.0</div>
                      </div>
                      <div onClick={() => handelSelectLineSize(1.5)}>
                        <div>
                          <div style={{height: '4px'}}></div>
                        </div>
                        <div>1.5</div>
                      </div>
                      <div onClick={() => handelSelectLineSize(2.0)}>
                        <div>
                          <div style={{height: '6px'}}></div>
                        </div>
                        <div>2.0</div>
                      </div>
                      <div onClick={() => handelSelectLineSize(3.0)}>
                        <div>
                          <div style={{height: '8px'}}></div>
                        </div>
                        <div>3.0</div>
                      </div>
                      <div onClick={() => handelSelectLineSize(4.0)}>
                        <div>
                          <div style={{height: '11px'}}></div>
                        </div>
                        <div>4.0</div>
                      </div>
                    </div>
                    )}

                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
          <div id="toolsSectionName">
            TakeOff
          </div>
        </div>

        <div id="toolsSection">
          <div id="Measure">
{/*             <div id='tool'>
              <div id="ToolsIcon">
                <img src={exportCsv} alt="exportCsv"/>
              </div>
              <div id="toolsIconName">
                CSV
              </div>
            </div> */}
            <div id='tool'>
              <div id="ToolsIcon">
                <img 
                  src={exportFile} 
                  alt="exportFile" 
                  onClick={() => navigate(`/manage/project/${projectId}/review`)}
                />
              </div>
              <div id="toolsIconName">
              Exporting
              </div>
            </div>
          </div>
          <div id="toolsSectionName">
            Export
          </div>
        </div>





      </div>
      <div id="Button">
        <div id="planName">
          1Wendy's #2254- Arlington, TX Revison 1 2024.03.20
        </div>

        <Group 
          yourDivisions={yourDivisions}
          csiDivisions={csiDivisions}
          handelSetCreateNewGroupOpen={handelSetCreateNewGroupOpen}
          selectedGroup={selectedGroup}
          CreateNewGroupRef={CreateNewGroupRef}
          handelSelectedGroup={handelSelectedGroup}
          setYourDivisions={setYourDivisions}
          setCsiDivisions={setCsiDivisions}
        />



      </div>

      {createNewGroup && (
        <CreateNewGroup 
        yourDivisions={yourDivisions}
        csiDivisions={csiDivisions}
        handelSetCreateNewGroupClose={handelSetCreateNewGroupClose}
        CreateNewGroupRef={CreateNewGroupRef}
        handelSelectedGroup={handelSelectedGroup}
        />
      )}


        {displayWarningDivUpdateScale && (
          <div id="MsgChangeScale" ref={updateScaleBox}>
            <div>
              <div id="header">
                <div id="headerText">
                  Update scale of existing measurements
                </div>
                <img src={close} alt="" onClick={handelCloseWarningDivUpdateScale}/>
              </div>
              <div id="warning">
                <img src={warning} alt="warning" />
                <div id="warningText">
                  Warning
                </div>
              </div>
              <div id="bodyText">
                Only one scale is allowed per page. Changing the scale will update any existing measurements on this page.
              </div>
              <div id="bottom">
                <div id="checkBox">
                  {scaleDSAChecked ? (
                    <img src={checkedCheckbox} alt="" onClick={handelDontShowItCheckBoxUpdateScale}/>
                  ):(<img src={uncheckedCheckbox} alt="" onClick={handelDontShowItCheckBoxUpdateScale}/>)
                  }
                    
                  <div id="checkBoxText">
                    Don't show again
                  </div>
                </div>
                <div id="button">
                  <div id="cancel" onClick={handelCloseWarningDivUpdateScale}>Cancel</div>
                  <div id="update" onClick={handelUpdateScale}>Update</div>
                </div>
              </div>
              
            </div>
          </div>
        )}
    </div>
    
  );
}


export default Nav;
