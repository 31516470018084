import React from 'react';
import Task from './Task';
import '../assetsManagement/section.css';
import IoCloudUpload from "../imgs/icloudupload.png";
import Pdf from "../imgs/pdf.png";
import { useNavigate } from 'react-router-dom';



const Section = ({plans, projectID, handelUploadClick}) => {
  const navigate = useNavigate();


 const handelTakeOffClick = () =>{
  const url = '/manage/project/'+projectID+'/takeoff' 
  navigate(url); 
 }


  const renderSection = () => {
    return(
      <div className="sections">
        <div className="section">
          <button>Add Client</button>
          <button>Add Project Address</button>
        </div>

        <div className="section">
          <h4 onClick={handelTakeOffClick}>Takeoffs {plans.length > 0 && (<div>({plans.length})</div>)}</h4>
          <div>
            <div id='upload' onClick={handelUploadClick}>
              <div><img src={IoCloudUpload} alt="IoCloudUpload" /></div>
              <div>Upload Plans</div>
            </div>
            {plans.map((plan,i) =>(
              <div id='plan' key={i}>
                <div><img src={Pdf} alt="Paperwork" /></div>
                <div>{plan.Name}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="section">
          <h4>Files & Photos (1)</h4>
          <div>
            <div id='upload'>
              <div><img src={IoCloudUpload} alt="IoCloudUpload" /></div>
              <div>Upload Files</div>
            </div>
          </div>

        </div>
        <div className="section">
          <h4>Tasks</h4>
          <Task />
        </div>
        <div className="section">
          <h4>Financial Overview</h4>
          <div className="financial-overview">
            <p>Want to see your financial breakdown? Add income or expenses here.</p>
            <button>Add Income</button>
            <button>Add Expense</button>
          </div>
        </div>
        <div className="section">
          <h4>Financial Documents</h4>
          <div className="financial-documents"></div>
        </div>
      </div>
    )
  }
  return (
    <>
    {renderSection()}
    </>
  );
}


export default Section;
