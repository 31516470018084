import axios from 'axios';
import {useState } from 'react';


export const Count = (
    zoom, lineSize, selectedColor, selectedGroup, AllCounts, setAllCounts, projectId, pageID
) => {

  const [currentCount, setCurrentCount] = useState(null);

  const handleMouseDownCount = (e) => {
    const { offsetX, offsetY } = e.evt;
    let countNumber
    if(AllCounts){
      countNumber = AllCounts.length + 1
    }else{
      countNumber = 1
    }
    if(!currentCount){
      setCurrentCount({
        id: Math.floor(100000000000 + Math.random() * 900000000000),
        name: 'count '+countNumber,
        category: selectedGroup.category,
        group: selectedGroup.group,
        type: 'cots',
        points: [{
          x: offsetX / zoom,
          y: offsetY / zoom
        }],
        lineWidth: (7 * lineSize),
        color: selectedColor,
        measure: 1,
        updatedLength: 1, 
        projectId: projectId, 
        pageID: pageID
      });
    }
    else{
      const addedCount ={
        ...currentCount,
        measure: currentCount.measure +1,
        points: [
          ...currentCount.points, 
          {
            x: offsetX / zoom,
            y: offsetY / zoom
          }
        ],
        lineWidth: (7 * lineSize),
        color: selectedColor,

      }
      setCurrentCount(addedCount)
    }

  };

  const finishDrawingCount = () => {

    if (currentCount){
      if(!AllCounts){
        setAllCounts([currentCount])
      }else{
        setAllCounts((prevCounts) => [...prevCounts, currentCount]);
      }
      const data = currentCount
      axios.post(`https://app.api.scae.co/api/measurements/addMeasure`,{data})
      setCurrentCount(null);
    }

  };
  const ExitDrawingCount = () =>{
    if (currentCount){
      setCurrentCount(null);
    }
  }



  return {
    handleMouseDownCount,
    ExitDrawingCount,
    finishDrawingCount,
    currentCount
  };
}

