import React, {useState, useEffect}from 'react';
import { useParams } from 'react-router'
import Section from './Section';
import Sidebar from './Sidebar';
import TakeOff from './TakeOff';
import axios from 'axios';
import UploadPlans from './UploadPlans';
import '../assetsManagement/AllPages.css';




const Manage = ({takeOffClick}) => {
  const [projectInfo , setProjectInfo] = useState({});
  const [companyInfo , setCompanyInfo] = useState([]);
  const [plans, setPlans] = useState([]);
  const [uploadClick, setUploadClick] = useState(false)
  const { id } = useParams();

  useEffect(() =>{
    const fetchData = async () => {
      try {
        const responseGetPlansInfo = await axios.get(`https://app.api.scae.co/api/management/getPlansInfo/${id}`);
        setPlans(responseGetPlansInfo.data)
        const response = await axios.get(`https://app.api.scae.co/api/management/${id}`);
        setProjectInfo(response.data.project)
        setCompanyInfo(response.data.companies)

      } catch (error) {

      }
    };

    fetchData();

  },[id, uploadClick])

const handelUploadClick = () =>{
  setUploadClick(!uploadClick)
}


  return (
    <>
    <Sidebar projectInfo={projectInfo} companyInfo={companyInfo}/>
    {takeOffClick ?(
      <TakeOff handelUploadClick ={handelUploadClick}/>  
    ):(
      <Section plans={plans} projectID={id} handelUploadClick ={handelUploadClick} /> 
    )}
    {uploadClick && <UploadPlans handelUploadClick={handelUploadClick}/>}
    </>
  );
}


export default Manage;
