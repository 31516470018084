import axios from 'axios';
import { useState } from 'react';

export const Polygon = (
  zoom, 
  lineSize,
  selectedColor, 
  selectedGroup, 
  ppi, 
  polygons, 
  setPolygons,
  projectId, 
  pageID
  
) => {

  const [polygon, setPolygon] = useState(null);
  const [currentPolygon, setCurrentPolygon] = useState(null);


  const finishDrawingPolygon = () => {
    if (polygon){
      if(!polygons){
        setPolygons([polygon])
      }else{
        setPolygons((prevPolygons) => [...prevPolygons, polygon]);
      } 
      const data = polygon
      axios.post(`https://app.api.scae.co/api/measurements/addMeasure`,{data})
      setCurrentPolygon(null);
      setPolygon(null)
    }

  };
  const ExitDrawingPolygon = () =>{
    if (currentPolygon){
          setCurrentPolygon(null);
    }
  }



  const handleMouseDownPolygon = (e) => {
    const { offsetX, offsetY } = e.evt;

    if (!currentPolygon) {
      setCurrentPolygon({
        points: [offsetX / zoom, offsetY / zoom, offsetX / zoom, offsetY / zoom],
        color: selectedColor,
        lineWidth: (lineSize * 2) /2
      });
    } 
    else {
      setCurrentPolygon((prevPolygon) => ({
        ...prevPolygon,
        points: [...prevPolygon.points, offsetX / zoom, offsetY / zoom],
      }));
      
      if(!polygon){
        let polygonNumber
        if(polygons){
          polygonNumber = polygons.length + 1
        }else{
          polygonNumber = 1
        }
        const newPolygon = {
            id: Math.floor(100000000000 + Math.random() * 900000000000),
            name: 'pol '+polygonNumber,
            group: selectedGroup.group,
            category: selectedGroup.category,
            type: 'poly',
            points: [currentPolygon.points],
            lineWidth: currentPolygon.lineWidth,
            color: selectedColor,
            measure: calculatePolygonArea(currentPolygon.points), 
            updatedLength: 1,
            projectId: projectId, 
            pageID: pageID
        }
        setPolygon(newPolygon);
      }
      else{
        const updatedPolygon = {
          ...polygon,
          measure: calculatePolygonArea(currentPolygon.points), 
          points: currentPolygon.points
        };
        setPolygon(updatedPolygon);
      }
    }
  };

  const handleMouseMovePolygon = (e) => {
    if (!currentPolygon) return;
    const { offsetX, offsetY } = e.evt;
    const newPoints = [...currentPolygon.points.slice(0, -2), offsetX / zoom, offsetY / zoom];
    setCurrentPolygon({ ...currentPolygon, points: newPoints });
  };


  const calculatePolygonArea = (pointsArray) => {
    let area = 0;
    const n = pointsArray.length / 2; // Number of vertices
  
    // Shoelace formula application
    for (let i = 0; i < n; i++) {
      const x1 = pointsArray[2 * i];
      const y1 = pointsArray[2 * i + 1];
      const x2 = pointsArray[2 * ((i + 1) % n)];
      const y2 = pointsArray[2 * ((i + 1) % n) + 1];
      
      area += (x1 * y2 - y1 * x2);
    }
  
    area = Math.abs(area) / 2;
    const baseArea = area / (7.5 * ppi);
  
    return baseArea;

  };
  



  return {
    handleMouseDownPolygon,
    handleMouseMovePolygon,
    finishDrawingPolygon,
    currentPolygon,
    ExitDrawingPolygon
  };
};
