import '../assetsTakeOff/Measurements.css';
import close from '../assetsTakeOff/CanvasTools/close.png';
import measure from '../assetsTakeOff/CanvasTools/measure.png';
import slideUpArrow from '../assetsTakeOff/CanvasTools/slideUpArrow.png';
import slideDownArrow from '../assetsTakeOff/CanvasTools/slideDownArrow.png';
import { useEffect, useState } from 'react';


const Measurements = (
  {
    AllLines,
    scaleRatio,
    AllRectangles,
    AllPolygons,
    AllCounts
  }
) =>{
  const [lines, setLines] = useState([]);
  const [rectangles, setRectangles] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [counts, setCounts] = useState([]);
  const [measureData, setMeasureData] = useState([])
  const [closeGroup, setCloseGroup] = useState([])

  const [individualMesure, setIndividualMesure] = useState({id:'213213s', data:[]})




 useEffect (() => {
  setIndividualMesure({id:'213213s', data:[]})
  if(AllLines){
    const indLines = AllLines.filter((line) => line.category === '')
    const lines = AllLines.filter((line) => line.category !== '')
    setLines(lines)
    if(indLines){
      setIndividualMesure((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...indLines]
      }));
    }

  }
  if(AllRectangles){
    const rects = AllRectangles.filter((rectangle) => rectangle.category !== '')
    const indRects = AllRectangles.filter((rectangle) => rectangle.category === '')
    setRectangles(rects)
    if(indRects){
      setIndividualMesure((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...indRects]
      }));
    }
  }
  if(AllPolygons){
    const polys = AllPolygons.filter((poly) => poly.category !== '')
    const indPolys = AllPolygons.filter((poly) => poly.category === '')
    setPolygons(polys)
    if(indPolys){
      setIndividualMesure((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...indPolys]
      }));
    }
  }
  if(AllCounts){
    const counts = AllCounts.filter((count) => count.category !== '')
    const indCounts = AllCounts.filter((count) => count.category === '')
    setCounts(counts)
    if(indCounts){
      setIndividualMesure((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...indCounts]
      }));
    }
  }

},[AllLines,AllRectangles, AllPolygons, AllCounts])

  useEffect (() => {

  const generateId = () => Math.floor(100000000000 + Math.random() * 900000000000);

  const combineData = (rectangles, polygons, lines, counts) => {
    const dataMap = new Map();
  
    const addToMap = (items, type) => {
      items.forEach(item => {
        const { category, group } = item;
  
        if (!dataMap.has(category)) {
          dataMap.set(category, { id: generateId(), groups: new Map() });
        }
  
        const categoryData = dataMap.get(category);
  
        if (!categoryData.groups.has(group)) {
          categoryData.groups.set(group, {
            typeMap: new Map([
              ['rec', { id: generateId(), measure: [] }],
              ['len', { id: generateId(), measure: [] }],
              ['cots', { id: generateId(), measure: [] }]
            ])
          });
        }
  
        const groupData = categoryData.groups.get(group);
        groupData.typeMap.get(type).measure.push(item);
      });
    };
  
    // Add rectangles and polygons under 'rec'
    if (rectangles) {
      addToMap(rectangles, 'rec');
    }
    if (polygons) {
      addToMap(polygons, 'rec');
    }
    
    // Add lines under 'len'
    if (lines) {
      addToMap(lines, 'len');
    }
  
    // Add counts under 'cots'
    if (counts) {
      addToMap(counts, 'cots');
    }
  
    // Convert the data structure from Map to the desired format
    const newData = [];
  
    dataMap.forEach((categoryData, category) => {
      const groups = [];
  
      categoryData.groups.forEach((groupData, group) => {
        groupData.typeMap.forEach((typeData, type) => {
          if (typeData.measure.length > 0) {
            groups.push({
              group,
              id: typeData.id, // Use the unique ID for each type
              type,
              measure: typeData.measure
            });
          }
        });
      });
  
      newData.push({ category, id: categoryData.id, groups });
    });
  
    return newData;
  };
  const newDate = combineData(rectangles, polygons, lines, counts);
  setMeasureData(newDate)
     
  },[lines,rectangles, polygons, counts])    




  const handelCloseGroup = (id) =>{
    setCloseGroup((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
   }
  


  const calculateGroupTotal = (data, column, groupType) =>{
    if(groupType === 'rec'){
      if(column === 'result'){
        let total = 0
        data.map((measure) => (
          total += measure.measure
        ))
        total = ((total * scaleRatio) / 3).toFixed(2)
        return total;
  
      }
      else{
        let total = 0
        data.map((measure) => (
          total += (measure.measure * measure.updatedLength)
        ))
        total = ((total * scaleRatio) / 3).toFixed(2)
        return total;
      }
    }
    if(groupType === 'len'){
      if(column === 'result'){
        let total = 0
        data.map((measure) => (
          total += measure.measure
        ))
        total = ((total * scaleRatio) / 12).toFixed(2)
        return total;
  
      }
      else{
        let total = 0
        data.map((measure) => (
          total += (measure.measure * measure.updatedLength)
        ))
        total = ((total * scaleRatio) / 12).toFixed(2)
        return total;
      }
    }
    if(groupType === 'cots'){
      if(column === 'result'){
        let total = 0
        data.map((measure) => (
          total += measure.measure
        ))
        return total.toFixed(0);
  
      }
      else{
        let total = 0
        data.map((measure) => (
          total += (measure.measure * measure.updatedLength)
        ))
        return total.toFixed(0);
      }
    }
  }


  return (
    <div id="measurements">
      <div id="mContents">
          <div id="top">
            <div id="headerText">
            {(closeGroup.includes('headerText')
              ) ? (
                <img 
                  onClick={() => handelCloseGroup('headerText')}
                  src={slideDownArrow} alt="slideDownArrow" 
                />
              ) : (
                <img 
                  onClick={() => handelCloseGroup('headerText')}
                  src={slideUpArrow} alt="chevronUp" 
                />
              )}
              Measurements
            </div>
          </div>
          {!closeGroup.includes('headerText') &&
            <div id="mainContent">
              <div id="mcTop">
                <div>Name</div>
                <div>Result</div>
                <div></div>
                <div>Total</div>
              </div>
              {measureData.map((category ,i) => (
              <div id="mcpCategory" key={i}>
                <div id="pcHeader">
                  <div id="categoryName">
                  {(closeGroup.includes(category.id)
                    ) ? (
                      <img 
                        onClick={() => handelCloseGroup(category.id)}
                        src={slideDownArrow} alt="slideDownArrow" 
                      />
                    ) : (
                      <img 
                        onClick={() => handelCloseGroup(category.id)}
                        src={slideUpArrow} alt="chevronUp" 
                      />
                    )}
                    {category.category}
                  </div>
                </div>
                {!(closeGroup.includes(category.id)) &&
                category.groups.map((group, i) => (
                <div id="mcpGroup" key={i}>
                  <div id="groupMeas">
                    <div>
                      {(closeGroup.includes(group.id)
                      ) ? (
                        <img 
                          onClick={() => handelCloseGroup(group.id)}
                          src={slideDownArrow} alt="slideDownArrow" 
                        />
                      ) : (
                        <img 
                          onClick={() => handelCloseGroup(group.id)}
                          src={slideUpArrow} alt="chevronUp" 
                        />
                      )}
                      
                      {group.group}
                    </div>
                    
                    <div>
                      {calculateGroupTotal(group.measure, 'result', group.type)}  
                      {group.type === 'rec' ? ' ft2' : group.type === 'len' ? ' ft' : ' Count'}
                    
                    </div>
                    <div><img src={measure} alt="measure" /></div>
                    <div>
                      {calculateGroupTotal(group.measure, 'total', group.type)}  
                      {group.type === 'rec' ? ' ft2' : group.type === 'len' ? ' ft' : ' Count'}
                    </div>
                  </div>
                  {!(closeGroup.includes(group.id)) && 
                    group.measure.map((item) =>(
                      <div id="singleMeas" key={item.name}>
                        <div>
                          <div id='gpLine' style={{ backgroundColor: item.color }}></div>
                          {item.name}
                        </div>
                        {group.type === 'rec' ? (
                          <>
                            <div>{((item.measure * scaleRatio) / 3).toFixed(2)} ft2</div>
                            <div><img src={measure} alt="measure" /></div>
                            <div>{(((item.measure * scaleRatio) / 3) * item.updatedLength).toFixed(2)} ft2</div>
                          </>
                        ):group.type === 'len' ?(
                          <>
                            <div>{((item.measure * scaleRatio) / 12).toFixed(2)} ft</div>
                            <div><img src={measure} alt="measure" /></div>
                            <div>{(((item.measure * scaleRatio) / 12) * item.updatedLength).toFixed(2)} ft</div>
                          </>
                        ):group.type === 'cots' &&(
                          <>
                            <div>{(item.measure)} Count</div>
                            <div><img src={measure} alt="measure" /></div>
                            <div>{item.measure * item.updatedLength} Count</div>
                          </>
                        )
                        }
                      
                      </div>
                    ))
                  }





                </div>
                ))}

              </div>
              ))}
              {individualMesure.data.length > 0 && (
              <div id="mcpCategory">
                <div id="pcHeader">
                  <div id="categoryName">
                  {(closeGroup.includes(individualMesure.id)
                    ) ? (
                      <img 
                        onClick={() => handelCloseGroup(individualMesure.id)}
                        src={slideDownArrow} alt="slideDownArrow" 
                      />
                    ) : (
                      <img 
                        onClick={() => handelCloseGroup(individualMesure.id)}
                        src={slideUpArrow} alt="chevronUp" 
                      />
                    )}
                    Individuals
                  </div>
                </div>
                {!(closeGroup.includes(individualMesure.id)) &&
                individualMesure.data.map((item, i) => (
                  <div id="singleMeas" key={i}>
                  <div>
                    <div id='gpLine' style={{ backgroundColor: item.color }}></div>
                    {item.name}
                  </div>
                  {item.type === 'rec' ? (
                    <>
                      <div>{((item.measure * scaleRatio) / 12).toFixed(2)} ft2</div>
                      <div><img src={measure} alt="measure" /></div>
                      <div>{(((item.measure * scaleRatio) / 12) * item.updatedLength).toFixed(2)} ft2</div>
                    </>
                  ): item.type === 'poly' ? (
                    <>
                      <div>{((item.measure * scaleRatio) / 12).toFixed(2)} ft2</div>
                      <div><img src={measure} alt="measure" /></div>
                      <div>{(((item.measure * scaleRatio) / 12) * item.updatedLength).toFixed(2)} ft2</div>
                    </>
                  ):item.type === 'len' ?(
                    <>
                      <div>{((item.measure * scaleRatio) / 12).toFixed(2)} ft</div>
                      <div><img src={measure} alt="measure" /></div>
                      <div>{(((item.measure * scaleRatio) / 12) * item.updatedLength).toFixed(2)} ft</div>
                    </>
                  ):item.type === 'cots' &&(
                    <>
                      <div>{(item.measure)} Count</div>
                      <div><img src={measure} alt="measure" /></div>
                      <div>{item.measure * item.updatedLength} Count</div>
                    </>
                  )
                  }
                
                </div>
                ))}
              </div>    
              )}
            </div>
          }
      </div>
      {!closeGroup.includes('headerText') &&
      <div id="bottom"></div>
      }
    </div>
  )

}

export default Measurements