import React, {useState} from 'react';
import '../assetsManagement/sideBar.css';
import { FaDoorOpen } from "react-icons/fa";
import { GoChevronUp, GoChevronDown } from "react-icons/go";
import { MdManageAccounts } from "react-icons/md";
import { CiBank } from "react-icons/ci";
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";




const Sidebar = ({projectInfo, companyInfo}) => {
  const [planningClick, setPlanningClick] = useState(false)
  const [managementClick, setManagementClick] = useState(false)
  const [financeClick, setFinanceClick] = useState(false)
  const [slideLeft, setSlideLeft] = useState(false)



  const sideBarToggle = (section) =>{
    if(section === 'Planning'){
      setPlanningClick(!planningClick)
    }
    else if(section === 'Management'){
      setManagementClick(!managementClick)
    }
    else if(section === 'Finance'){
      setFinanceClick(!financeClick);
    }
    else if(section === 'slideLeft'){
      setSlideLeft(!slideLeft)
    }
  }


  return (
    <aside className="sidebar" 
      style={{
        width: slideLeft ? '0' : '210px',
        padding: slideLeft ? '10px' : '20px',
        }}>
      <div id="slideLeft" onClick={() => sideBarToggle('slideLeft')} >
        {slideLeft ? <VscChevronRight/> : <VscChevronLeft/> }
      </div>
      {!slideLeft && (
        <>
          <h3>{projectInfo.ProjectName}</h3>
          <div id='sideBarSections' >
            <div id="sbsSection">
              <div id='sbsTop' onClick={() => sideBarToggle('Planning')}>
                <div><FaDoorOpen /></div>
                <div > Planning </div>
                <div> {planningClick ? <GoChevronDown /> : <GoChevronUp />}</div>
              </div>

              <div id='sbsButtons' style={{maxHeight: planningClick ? '0' : '200px'}}>
                  <div>Estimates</div>
                  <div>Takeoffs</div>
              </div>

            </div>
    
            <div id="sbsSection">
              <div id='sbsTop' onClick={() => sideBarToggle('Management')}> 
                <div><MdManageAccounts /></div>
                <div> Management </div>
                <div>{managementClick ? <GoChevronDown /> : <GoChevronUp />}</div>  
              </div>
              <div id='sbsButtons' style={{maxHeight: managementClick ? '0' : '200px'}}>
                <div>Files & Photos</div>
                <div>Schedule</div>
                <div>Tasks</div>
                <div>Daily Logs</div>
              </div>
            </div>
    
            <div id="sbsSection">
              <div id='sbsTop' onClick={() => sideBarToggle('Finance')}> 
                <div><CiBank /></div>
                <div> Finance </div>
                <div>{financeClick ? <GoChevronDown /> : <GoChevronUp />}</div> 
              </div>
              <div id='sbsButtons' style={{maxHeight: financeClick ? '0' : '200px'}}>
                <div>Invoices</div>
                <div>Purchase Orders</div>
                <div>Change Orders</div>
                <div>Retainers & Credits</div>
                <div>Financial Overview</div>
              </div>
            </div>
    
          </div>
        </>
      )}
    </aside>
  );

}

export default Sidebar;
