import React, {Component} from "react";
import '../assets/Tables.css';
import axios from 'axios';
import $ from 'jquery'; // Import jQuery
import sortIcon from '../imgs/icons8-align-justify-24.png'
import { formatNumberWithCommas, formatDateFromMySQL } from './Function';


class Tables extends Component {

  state ={
    table_data : [],
    columns : [],
    companies : [],
    selectedRowID : null,
    selectedCellIndex : null
  }

  componentDidUpdate(prevProps) {
    // Check if selectedTable prop has changed
    if (prevProps.selectedTable !== this.props.selectedTable) {
      this.GetTableData(this.props.selectedTable.Table_Name);
    }
  }


  GetTableData = async (table_name) => {
    this.setState({
      table_data : [],
      columns : [],
      companies : []
    });
    if(table_name === 'Home'){
      $('#Projects-main-page > #body').css('display','flex');
    }
    else{
      try{
        const res = await axios.post('https://app.api.scae.co/api/get_table', {table_name: table_name});
        this.setState({
          table_data : res.data.table_data,
          columns : res.data.columns,
          companies : res.data.companies
        });
/*         this.createTable(data.columns);
        this.insertDataInTable(data); */
  
  
      }catch (error){
        console.error(error);
      }
    }




  };


  

  

  handleCellClick(){
    console.log('sss')
  }
  
  


  renderTable(){
    const { table_data, columns, companies} = this.state;

    if(table_data.length > 0){
      return(
        <div id="tables">
          <div id='table'>
            <table>
              <thead>
                  <tr>
                    <td></td>
                  {columns.map((col,i) =>(
                      <td key={i}>
                        <div>
                          <img src={sortIcon} alt="icon" />
                          <div>{col.Column_Name}</div>
                        </div>
                      </td>
                    ))}
                  </tr>
              </thead>
              <tbody>
                  {table_data.map((data,i)=>(
                    <tr
                    key={i}
                    id={data.Project_ID}>
                      <td><div id="number_column">{i+1}</div></td>
                      {columns.map((col,i) =>(
      
      
                        col.Column_Name === 'CompanyName' && col.Table_ID === 'TB_1111' ? (
                          <td key={i}>
                            <div id={col.Column_Name}>
                              {companies.flat().map((company,c) => {
                                if(company.Project_ID === data.Project_ID){
                                  return (
                                    <div key={c}>
                                      <div></div>
                                      <div>{company.CompanyName}</div>
                                    </div>
                                  )
                                }else{
                                  return ''
                                }
                              })}
                            </div>
                          </td>
                        ) : 
                        col.Type === 'number' ? (
                          <td key={i}>
                            <div id={col.Column_Name}>
                              {formatNumberWithCommas(data[col.Column_Name])}
                            </div>
                          </td>
                        ) : 
                        col.Type === 'date' ? (
                          <td key={i}>
                            <div id={col.Column_Name}>
                              {formatDateFromMySQL(data[col.Column_Name])}
                            </div>
                          </td>
                        ) :                  
                        col.Type === 'link' ? (
                          <td key={i}>
                            <div id={col.Column_Name}>
                              <a href={data[col.Column_Name]}>{data[col.Column_Name]}</a>
                            </div>
                          </td>
                        ) : (
                          <td key={i}>
                            <div id={col.Column_Name}>{data[col.Column_Name]}</div>
                          </td>
                        )
      
      
      
                      ))}
      
      
      
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div id='row-click'>
            <div id='add_row_click'> + Add Row</div>
            <div id='remove_row_click'> + Remove Row</div>
            <div id='remove_row_click'> Delete Selected</div>
        </div>

      </div>

      )
    }
  }

  render(){
     return(
      this.renderTable()
     );
  }
}

export default Tables;
